import React, { useEffect, useState } from 'react'
import { getLastPaieUser } from '../../Services/paie/paie'
import { PDFViewer } from '@react-pdf/renderer'
import QuittancePDF from './QuittanceDeSoldePdf'
import { getEntrepriseByCode } from '../../Services/Pointeuse/EntrepriseApi'
import { handleKeyPress_number } from '../../Utils/InputUtils'

export default function QuittanceDeSolde({ exercice,perso, types_contrat, userId, t, conges, user }) {
    const mois_actuel = new Date().getMonth()
    const listmois = ['Janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];

    const [listPaie, setlistPaie] = useState([])
    const [societe, setSociete] = useState([])
    const [selectedMois,setselectedMois]=useState(listmois[mois_actuel])
    const [selectedExercice,setselectedExercice]=useState(exercice[0]?.code_exercice)


    useEffect(() => {
        getEntrepriseByCode().then((res) => {
            if (res?.data) {
                setSociete(res.data)

            }
        });
    }, [])

    useEffect(() => {
       
        getLastPaieUser(userId,selectedMois,selectedExercice).then((res) => {
            if (res.status === 200) {


                const findPaieMois = res.data.find(item => item.status === "mois")
                const list = res.data.map(element => {
                    return { ...element, checked: true }
                });
                const nbrjr = perso.calandrier[listmois[mois_actuel]]
                const salairejourne = parseFloat(findPaieMois?.net_a_payer) / nbrjr
                console.log('list',list)
                const soldeconge = { status: "conge", solde: conges.solde, net_a_payer: (salairejourne * conges.solde).toFixed(3), checked: true }
                setlistPaie([...list, soldeconge])
            }
        })
    }, [selectedExercice,selectedMois,userId, perso, conges, user])
    const changevalue = (index, name, value) => {

        const copie = [...listPaie]
        copie[index][name] = value

        setlistPaie(copie)
    }
    return (

        <div className="row">
            <div className="col-xl-12 col-md-12 col-lg-12">
                <div className={false ? "card  card-fullscreen" : "card"}>
                    <div className="card-header row border-0">
                    <h4 className="card-title col-2">{t("Exercice")}</h4>
                        <select className="form-control col-3"  value={selectedExercice} onChange={(e)=>setselectedExercice(e.target.value)}>
                            {exercice && exercice.map((item,index) => (
                                <option key={index} value={item.code_exercice}>
                                    {item.code_exercice}
                                </option>
                            ))}

                        </select>
                       
                    <h4 className="card-title col-2">{t("Mois")}</h4>
                        <select className="form-control col-3"  value={selectedMois} onChange={(e)=>setselectedMois(e.target.value)}>
                            {listmois && listmois.map((item,index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}

                        </select>
                       
                    </div>

                    <div className="card-body">
                        <div>
                            {listPaie && listPaie.map((item, index) => (
                                <>
                                    {item.status === 'mois' ? (

                                        <div className='row mb-3'>
                                            <input className='col-1' type='checkbox' checked={item.checked} onClick={() => { changevalue(index, "checked", !item.checked) }} />
                                            <strong className='col-3'>Mois {item.mois} </strong>
                                            <input className='col-4' onKeyPress={handleKeyPress_number} type="text" onChange={(e) => { changevalue(index, "net_a_payer", e.target.value) }} value={item.net_a_payer||0} />
                                        </div>

                                    ) : item.status === 'conge' ? (
                                        <div className='row mb-3'>
                                            <input className='col-1' type='checkbox' checked={item.checked} onClick={() => { changevalue(index, "checked", !item.checked) }} />
                                            <strong className='col-3'>Solde conge : {item.solde} </strong>
                                            <input className='col-4' onKeyPress={handleKeyPress_number} type="text" value={item.net_a_payer||0} onChange={(e) => { changevalue(index, "net_a_payer", e.target.value) }} />
                                        </div>) :
                                        <div className='row mb-3'>
                                            <input className='col-1' type='checkbox' checked={item.checked} onClick={() => { changevalue(index, "checked", !item.checked) }} />
                                            <strong className='col-3'>Prime : {item.prime} </strong>
                                            <input className='col-4' onKeyPress={handleKeyPress_number} type="text" value={item.net_a_payer||0} onChange={(e) => { changevalue(index, "net_a_payer", e.target.value) }} />
                                        </div>
                                    }
                                </>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <PDFViewer style={{ width: "100%", height: "800px" }}>
                <QuittancePDF
                    perso={perso}
                    types_contrat={types_contrat}
                    list={listPaie.filter(item => item.checked)}
                    user={user}
                    societe={societe} />
            </PDFViewer>
            {/* leflet */}
        </div>

    )
}
