import React, { useEffect, useState } from 'react'
import { getuser_personnel_actif } from '../../../Services/Pointeuse/UsersApi'
import { t } from "i18next";
import { getparam_paie, getparam_paie_active, getparam_rub_paie_active, getuser_rub_codesociete } from '../../../Services/paie/parametrage_paie';
import { getBaremeIrppByCode } from '../../../Services/Pointeuse/BaremeIrppApi';
import { getAvantage_nature_active, getEntrepriseByCode, getExercice } from '../../../Services/Pointeuse/EntrepriseApi';
import { toast } from 'react-toastify';
import { addPaie, cloturerpaie, cloturerpaie_id, deletelignepaie, getPaie, getpaie_year, getpaie_yearallUsers, getReportImpot } from '../../../Services/paie/paie';
import Fichepaie from '../files/Fichepaie';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import { IoRefresh } from 'react-icons/io5';
import swal from "sweetalert";
import FichepaieModel2 from '../files/FichepaieModel2';

import { IoPersonRemoveSharp } from "react-icons/io5";

import { Modal } from 'react-bootstrap';
import CardPerso from '../../Users/CardPerso';
import Info_paie_employe from './Info_paie_employe';
import { arondisnumber, arrondi_entier, float_decimale, formatFloat } from '../../../Utils/helper';
import moment from 'moment';
import List_user from './List_user';
import { calcul_irpp } from '../CalculIrpp';


export default function MatricePaie(props) {
    //const user_selected = props?.location.state?.user_selected || [];
    const prime = props?.location.state?.prime || null
    const [user_selected, setuser_selected] = useState(props?.location.state?.user_selected || [])
    const [list_rubriques, setlist_rubriques] = useState([])
    const [list_avantages, setlist_avantages] = useState([])
    const [change, setchange] = useState(false)
    const [list_regule, setlist_regule] = useState([])
    const [data, setdata] = useState([])
    const [data_employee, setdata_employee] = useState()
    const [fiche, setfiche] = useState(false)
    const [show, setshow] = useState(false)
    const [data_map, setdata_map] = useState([])
    const [loading, setloading] = useState(false)
    const [irpp, setIrpp] = useState([])
    const [click, setclick] = useState()
    const  [listupdateReport , setlistupdateReport]=useState([])
    const [listReportImpot,setListReportImpot]=useState([])
    const [param_paie_active, setparam_paie_active] = useState()
    var dateObj = new Date();
    var result = dateObj.toISOString().split("T")[0];
    var month = result.split("-")[1];
    var year = result.split("-")[0];
    var newdate = year + "-" + month;
    const [ajout, setajout] = useState(false)
    const [dateP, setdateP] = useState()

    const [data_change, setdata_change] = useState([])
    const [exercice, setexercice] = useState()
    const [exercice_selectionne, setexercice_selectionne] = useState()
    const [exercice_precedent, setexercice_precedente] = useState()

    const [mois_selected, setmois_selected] = useState()

    const [listpaie, setlistpaie] = useState([])
    const [lastpaies, setlastpaies] = useState([])

    const [lastpaie, setlastpaie] = useState([])

    const [regule_impo, setregule_impo] = useState(false)
    const [societe, setSociete] = useState(null);
  useEffect(() => {
        getBaremeIrppByCode(exercice_selectionne).then((res) => {
           
            setIrpp(res.data)

        })
    }, [exercice_selectionne])
    useEffect(() => {

        getEntrepriseByCode().then((res) => {
            const data = res.data;
            data.matricule_fiscal = data?.matricule_fiscal?.replace(/\//g, '');

            setSociete(data);
        });
    }, []);

    const listmois = ['Janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre', 'prime', '13eme']
    useEffect(() => {
        if (props?.location.state?.exercice) {
            setexercice_selectionne(props?.location.state?.exercice)
            getparam_paie(props?.location.state?.exercice).then((res) => {
                if (res.status === 200) {
                    setparam_paie_active(res.data.data[0])
                }
            })
        } else {

            getparam_paie_active().then((res) => {
                if (res.status === 200) {
                    setparam_paie_active(res.data.data[0])
                }
            })
        }
        if (props?.location.state?.date) {

            setdateP(props?.location.state?.date)
            setmois_selected(parseInt(props?.location.state?.date.split('-')[1]) - 1)
        }
        if (props?.location.state?.exercice_precedent) {

          setexercice_precedente(props?.location.state?.exercice_precedent?.id)
        }
    }, [props])
    const handleChangeDate = (date) => {

        setclick({
            clicked: false,
            index: -1,
        })
        // alert(date)

        setmois_selected(parseInt(date))
        let mois = parseInt(date) + 1
        mois = mois < 10 ? `0${mois}` : mois;
        const year = dateP.split('-')[0]
        const dateselected = year + '-' + mois

        setdateP(dateselected);

    };
    useEffect(() => {
        getExercice().then((res) => {

            setexercice(res.data);

        })
    }, [])

    useEffect(() => {
        getparam_rub_paie_active().then((res) => {

            setlist_rubriques(res.data.data);
        });

        getAvantage_nature_active().then((res) => {
            setlist_avantages(res.data.data)
        })
    }, [])
    useEffect(() => {
        if (dateP) {
            setloading(true)

            getuser_personnel_actif(dateP).then((res) => {
                setdata(res.data)
            })
            const mois = listmois[parseInt(dateP.split('-')[1]) - 1];
            const lastmois = parseInt(dateP.split('-')[1]) - 1 !== 0 ? listmois[parseInt(dateP.split('-')[1]) - 2] : null;
            const year = dateP.split('-')[0];
            var s
            if (prime?.moisplus) {
                s = 'moisplus'
            } else if (prime?.prime) {
                s = 'prime'
            } else {
                s = 'mois'
            }
            getPaie(mois, lastmois, year, s, prime?.libelle || null).then((res) => {
                if (res.status === 200 && res.data?.data?.success !== 0) {
                    setlistpaie(res.data.listpaie)
                    setlastpaie(res.data.lastpaie)
                    if (res.data.listpaie.length !== 0) {

                        const list_user = res.data.listpaie?.map(item => parseInt(item.id_users));
                        const list = [...list_user, ...user_selected]
                        setuser_selected(list)

                    }


                } else {
                    toast.error(res.data?.data.message)
                }
            })
        }


    }, [dateP])
  
    useEffect(()=>{
        console.log('exercice_precedent',exercice_precedent)
        getReportImpot(exercice_precedent).then((res)=>{
            if(res.status===200){

                setListReportImpot(res?.data)
            }
        })
    },[exercice_precedent])
    const calcul_paie = () => {

        if (irpp.length !== 0 && data.length !== 0 && param_paie_active && Array.isArray(listpaie)) {
            const list_user_selected = user_selected.length !== 0 ? data.filter(objet => user_selected.includes(objet.user_id)).reverse() : data;
            getuser_rub_codesociete().then((res) => {

                if (res.status === 200) {

                    const list_rub = res.data.ru.map(item => {
                        const copie = { ...item }
                        copie.date_debut = copie.date_debut.split('T')[0]
                        copie.date_fin = copie.date_fin.split('T')[0]
                        return copie

                    });

                    const list_avantage = res.data.Av.map(item => {
                        const copie = { ...item }
                        copie.date_debut = copie.date_debut.split('T')[0]
                        copie.date_fin = copie.date_fin.split('T')[0]
                        return copie

                    });;
                    const list_changes = [...data_change]
                    const listuser = list_user_selected.map((element) => {
                        let updatedelement = { ...element }

                        let find_paie



                        find_paie = listpaie?.find((item) => parseInt(item.id_users) === parseInt(element.user_id));


                        if (find_paie) {
                            console.log("find")
                            // setuser_selected((prev)=>[...prev,find_paie.user_id])
                            updatedelement.saved = 1;
                            if (find_paie.cloturer === 1) {
                                updatedelement.soldeconge = find_paie.soldeconge

                                updatedelement.acquisconge = find_paie.acquisconge
                                updatedelement.servisconge = find_paie.servisconge;
                            }
                            updatedelement.retenu_salaire_hsup1 = find_paie.retenu_salaire_hsup1
                            updatedelement.retenu_salaire_hsup2 = find_paie.retenu_salaire_hsup2
                            updatedelement.regule_impo = find_paie.regule_impo
                            updatedelement.taux_cnss = param_paie_active.val_cnss;
                            updatedelement.CP = param_paie_active.val_CP
                            updatedelement.cloturer = find_paie.cloturer;
                            updatedelement.code_paie = find_paie.code;
                            updatedelement.nb_s = find_paie.nb_s;
                            updatedelement.salaire_heure = find_paie.salaire_heure;
                            updatedelement.salaire_journee = find_paie.salaire_journee;
                            updatedelement.heure_supp_t1 = find_paie.heure_supp_t1;
                            updatedelement.heure_supp_t2 = find_paie.heure_supp_t2;
                            updatedelement.h_sup_t1 = find_paie.h_sup_t1
                            updatedelement.h_sup_t2 = find_paie.h_sup_t2
                            updatedelement.total_supp_t1 = find_paie.total_supp_t1
                            updatedelement.total_supp_t2 = find_paie.total_supp_t2
                            updatedelement.salaire_heure_t1 = find_paie.salaire_heure_t1
                            updatedelement.salaire_heure_t2 = find_paie.salaire_heure_t2
                            updatedelement.Nbr_h_jr = find_paie?.Nbr_h_jr
                            updatedelement.annee = find_paie?.annee

                            updatedelement.net_servi_avant_arrondi = find_paie.net_servi_avant_arrondi
                            updatedelement.difference = find_paie.difference
                            if (find_paie.correction_jour_presence !== null) {
                                const datachange = { userid: updatedelement.user_id, name: 'total_present', oldvalue: find_paie.correction_jour_presence, newvalue: find_paie.jour_presence }
                                list_changes.push(datachange)

                            }
                            updatedelement.total_present = find_paie.jour_presence !== null ? find_paie.jour_presence : 0

                            if (find_paie.correction_jour_absence !== null) {
                                const datachange = { userid: updatedelement.user_id, name: 'Njr_Ab', oldvalue: find_paie.correction_jour_absence, newvalue: find_paie.jour_absence }
                                list_changes.push(datachange)

                            }
                            updatedelement.total_Ab = find_paie.jour_absence !== null ? find_paie.jour_absence : 0
                            if (find_paie.correction_jour_conge !== null) {
                                const datachange = { userid: updatedelement.user_id, name: 'total_conge', oldvalue: find_paie.correction_jour_conge, newvalue: find_paie.jour_conge }
                                list_changes.push(datachange)

                            }
                            updatedelement.total_conge = find_paie.jour_conge !== null ? find_paie.jour_conge : 0

                            if (find_paie.correction_salaire_base !== null) {
                                const datachange = { userid: updatedelement.user_id, name: 'salaire_base', oldvalue: find_paie.correction_salaire_base, newvalue: find_paie.salaire_base }
                                list_changes.push(datachange)
                            }
                            updatedelement.total_jr_ferire = find_paie.jour_ferier_paye !== null ? find_paie.jour_ferier_paye : 0

                            updatedelement.salaire_base = find_paie.salaire_base !== null ? find_paie.salaire_base : 0


                            setdata_change(list_changes)
                            updatedelement.nbrjtravaille = find_paie.nbj_travaille !== null ? find_paie.nbj_travaille : 0
                            updatedelement.total = parseFloat(updatedelement.total_conge) || 0 + parseFloat(find_paie.jour_presence) || 0 + parseFloat(find_paie.jour_ferier_paye) || 0
                            let salaire_journee = (parseFloat(updatedelement.salaire_base) / updatedelement.nbrjtravaille)
                            const calcul_salaire = CalculSalaire(updatedelement.total, salaire_journee)
                            updatedelement.salaire_retenue = arondisnumber((calcul_salaire.salaire_journee * updatedelement.total_Ab), 3);

                            updatedelement.salaire_journee = calcul_salaire.salaire_journee
                            updatedelement.salaire_p = arondisnumber((updatedelement.salaire_journee * updatedelement.total_present), 3)
                            updatedelement.salaire_c = arondisnumber((updatedelement.salaire_journee * updatedelement.total_conge), 3)
                            updatedelement.salaire_f = arondisnumber((updatedelement.salaire_journee * updatedelement.total_jr_ferire), 3)

                            updatedelement.salaire_base_calculer = find_paie.salaire_base_mois




                            /*********** */
                            const findrub_user = find_paie.paie_ligne.map(item => {
                                const copie = { ...item }
                                copie.date_debut = copie.date_debut?.split('T')[0]
                                copie.date_fin = copie.date_fin?.split('T')[0]
                                return copie

                            });
                            updatedelement.rubs = findrub_user
                            updatedelement.rubs_indemnite = findrub_user.filter(item => item.champs === 'indemnite');
                            updatedelement.rubs_retenue = findrub_user.filter(item => item.champs === 'retenue');

                            updatedelement.avantage_fiscaux = findrub_user.filter(item => item.champs === 'avantages_fiscaux');

                            const find_avantage = list_avantage?.filter(item => item.champs === 'avantages');
                            updatedelement.avantages = find_avantage

                            updatedelement.total_rub = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
                                if (curr.presence === 1) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                                    } else {
                                        return acc + (curr.valeur_mensuel * updatedelement.total);
                                    }

                                } else {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                                    } else {
                                        return acc + (curr.valeur_mensuel);
                                    }
                                };

                            }, 0) || 0;

                            updatedelement.total_retenue = updatedelement.rubs_retenue?.reduce((acc, curr) => {
                                if (curr.presence === 1) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                                    } else {
                                        return acc + (curr.valeur_mensuel * updatedelement.total);
                                    }

                                } else {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                                    } else {
                                        return acc + (curr.valeur_mensuel);
                                    }
                                };
                            }, 0) || 0;

                            updatedelement.total_av = find_avantage?.reduce((acc, curr) => {
                                if (curr.presence === 1) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                                    } else {
                                        return acc + (curr.valeur_mensuel * updatedelement.total);
                                    }

                                } else {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                                    } else {
                                        return acc + (curr.valeur_mensuel);
                                    }
                                };
                            }, 0) || 0;

                            updatedelement.total_rub_cnss = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
                                if (curr.cnss === 1) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + (curr.valeur_annuel / updatedelement.nb_s);
                                    } else {
                                        return acc + curr.valeur_mensuel;
                                    }


                                }
                                else {
                                    return acc + 0;

                                }

                            }, 0);

                            updatedelement.total_rub_non_irpp = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
                                if (curr.irpp === 0) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + (curr.valeur_annuel / updatedelement.nb_s);
                                    } else {
                                        return acc + curr.valeur_mensuel;
                                    }

                                }
                                else {
                                    return acc + 0;

                                }
                            }, 0);
                            updatedelement.total_rub_irpp = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
                                if (curr.irpp === 1) {

                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + (curr.valeur_annuel / updatedelement.nb_s);
                                    } else {
                                        return acc + curr.valeur_mensuel;
                                    }


                                }
                                else {
                                    return acc + 0;

                                }
                            }, 0);


                            updatedelement.total_avantage_fiscaux = updatedelement.avantage_fiscaux?.reduce((acc, curr) => {

                                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                    return acc + curr.valeur_annuel;
                                } else {
                                    return acc + (curr.valeur_mensuel * updatedelement.nb_s);
                                }
                            }, 0);



                            /**/
                            updatedelement.enfant = find_paie.enfant;
                            updatedelement.totalenfant = find_paie.totalenfant;
                            updatedelement.enfant_handicape = find_paie.enfant_handicape;
                            updatedelement.totalenfant_h = find_paie.totalenfant_h;
                            updatedelement.enfant_N_B = find_paie.enfant_N_B;
                            updatedelement.totalenfantN_b = find_paie.totalenfantN_b;
                            updatedelement.Parent_charge = find_paie.Parent_charge;
                            updatedelement.totalparent = find_paie.totalparent;
                            updatedelement.chef_famille = find_paie.chef_famille;
                            updatedelement.tauxchef_famille = find_paie.tauxchef_famille;

                            updatedelement.commission = find_paie.indemnites
                            // updatedelement = calculbrut(updatedelement)

                            updatedelement.rs_cnss = find_paie.retenue_cnss;
                            updatedelement.total_brut = find_paie.salaire_brut
                            updatedelement.brut_cnss = parseFloat(updatedelement.salaire_base_calculer) + parseFloat(updatedelement.total_rub_cnss);

                            updatedelement.salaire_imposable = find_paie.salaire_imposable;
                            /********************************************************/
                            updatedelement.salaire_ans_imposable = find_paie.salaire_ans_imposable !== null || 0;
                            updatedelement.moindixp = updatedelement.salaire_ans_imposable;
                            if (param_paie_active.dix_p === 1) {
                                const moindixp = updatedelement.salaire_ans_imposable * (param_paie_active.val_dix_p / 100);

                                if (moindixp > param_paie_active.montant_dix_p) {
                                    updatedelement.moindixp = updatedelement.salaire_ans_imposable - param_paie_active.montant_dix_p
                                } else {
                                    updatedelement.moindixp = updatedelement.salaire_ans_imposable - moindixp
                                }
                            }

                            //  updatedelement.net = updatedelement.total_rub_non_irpp + updatedelement.moindixp + updatedelement.totalenfantN_b + updatedelement.totalparent + updatedelement.totalenfant_h + updatedelement.tauxchef_famille + updatedelement.totalenfant;
                            updatedelement.net = updatedelement.moindixp - updatedelement.total_rub_non_irpp - updatedelement.totalenfant - updatedelement.totalenfant_h - updatedelement.totalenfantN_b - updatedelement.totalparent - updatedelement.tauxchef_famille;


                            // const res_irpp = calcul_irpp(updatedelement.net, nb_s);
                            updatedelement.calcul_irpp_css_mois = find_paie.calcul_irpp_css_mois;
                            updatedelement.calcul_irpp_mois = find_paie.calcul_irpp_mois;
                            updatedelement.irpp_fiche = find_paie.irpp_fiche;
                            updatedelement.css_fiche = find_paie.css_fiche;

                            updatedelement.montantcss = float_decimale((updatedelement.calcul_irpp_css_mois - updatedelement.calcul_irpp_mois), 3)
                            updatedelement.salaire_net = find_paie.salaire_net
                            updatedelement.salaire_irpp = (updatedelement.calcul_irpp_mois !== null && updatedelement.calcul_irpp_mois !== 0) ? float_decimale(((updatedelement.salaire_imposable || 0) - updatedelement.calcul_irpp_mois), 3) : 0;
                            updatedelement.unp = (updatedelement.calcul_irpp_mois !== null && updatedelement.calcul_irpp_mois !== 0) ? float_decimale((updatedelement.calcul_irpp_css_mois - updatedelement.calcul_irpp_mois), 3) : 0
                            updatedelement.net_servi = find_paie.net_a_payer
                            //  updatedelement.difference = lastpaie.find(item=>item.id_users === updatedelement.id_users)?.difference || 0
                            // updatedelement = calculbrut(updatedelement)
                            return updatedelement;

                        }
                        else {
                            const nb_s = updatedelement.nbr_salaire === 0 ? param_paie_active.nb_salaire : updatedelement.nbr_salaire
                            updatedelement.nb_s = nb_s;
                            const jsonObject = (updatedelement.calandrier !== '' && updatedelement.calandrier !== null) ? JSON.parse(updatedelement.calandrier) : JSON.parse(param_paie_active.calandrier);
                            if (prime && prime.libelle === "13eme") {
                                const findPaies = lastpaies?.filter(item=>parseInt(item.id_users) ===parseInt(updatedelement.user_id))
                                const ttt = findPaies?.reduce((acc, cur) => acc + cur.salaire_base, 0);
                                
                                const totalccccc =parseFloat(ttt)/findPaies?.length
                                const salaire_base_mmm = (updatedelement.salaire_base *12)
                                updatedelement.nb_s=findPaies?.length
                                updatedelement.salaire_base=totalccccc
                                console.log('salaire_base',updatedelement.salaire_base)
                               
                            }
                            updatedelement.CP = param_paie_active.val_CP
                            updatedelement.retenu_salaire_hsup1 = 1
                            updatedelement.retenu_salaire_hsup2 = 1
                            updatedelement.nbrjtravaille = getnombrejourmois(jsonObject)
                            updatedelement.total_coriger = updatedelement.total_coriger ? updatedelement.total_coriger : 0
                            updatedelement.total_jr_ferire = updatedelement.total_jr_ferire ? updatedelement.total_jr_ferire : 0
                            updatedelement.salaire_heure = updatedelement.salaire_heure || 0
                            updatedelement.salaire_journee = updatedelement.salaire_journee || 0
                            updatedelement.heure_supp_t1 = updatedelement.heure_supp_t1 || 0
                            updatedelement.heure_supp_t2 = updatedelement.heure_supp_t2 || 0
                            updatedelement.h_sup_t1 = updatedelement.h_sup_t1 || param_paie_active.taux_heure_sup_t1
                            updatedelement.h_sup_t2 = updatedelement.h_sup_t2 || param_paie_active.taux_heure_sup_t2
                            updatedelement.total_supp_t1 = updatedelement.total_supp_t1 || 0
                            updatedelement.total_supp_t2 = updatedelement.total_supp_t2 || 0
                            updatedelement.salaire_heure_t1 = updatedelement.salaire_heure_t1 || 0
                            updatedelement.salaire_heure_t2 = updatedelement.salaire_heure_t2 || 0
                            updatedelement.Nbr_h_jr = updatedelement.Nbr_h_jr || 0

                            updatedelement.total_P = updatedelement.total_P ? updatedelement.total_P : updatedelement.nbrjtravaille
                            updatedelement.total_present = updatedelement.total_coriger + updatedelement.total_P;
                            updatedelement.total = updatedelement.total ? updatedelement.total : updatedelement.total_present;

                            updatedelement.total_Ab = updatedelement.total_Ab ? updatedelement.total_Ab : 0;

                            updatedelement.total_conge = updatedelement.total_conge ? updatedelement.total_conge : 0
                            let salaire_journee = (parseFloat(updatedelement.salaire_base) / updatedelement.nbrjtravaille)

                            const calcul_salaire = CalculSalaire(updatedelement.total, salaire_journee)
                            updatedelement.salaire_retenue = (calcul_salaire.salaire_journee * updatedelement.total_Ab);
                            updatedelement.salaire_journee = calcul_salaire.salaire_journee

                            updatedelement.salaire_p = updatedelement.salaire_journee * updatedelement.total_present
                            updatedelement.salaire_c = updatedelement.salaire_journee * updatedelement.total_conge
                            updatedelement.salaire_f = updatedelement.salaire_journee * updatedelement.total_jr_ferire
                            updatedelement.salaire_base_calculer = calcul_salaire.salaire_base_calculer + updatedelement.total_supp_t1 + updatedelement.total_supp_t2
                     
                            const findrub_user = list_rub.filter(item => item.user_code === element.code && ((item.permanent === 0 && parseInt(item.date_debut.split('-')[1]) <= dateP.split('-')[1] <= item.date_fin.split('-')[1]) || item.permanent === 1));

                            const find_avantage = list_avantage?.filter(item => item.user_code === element.code && ((item.permanent === 0 && parseInt(item.date_debut.split('-')[1]) <= dateP.split('-')[1] <= item.date_fin.split('-')[1]) || item.permanent === 1));

                            updatedelement.rubs = findrub_user
                            updatedelement.rubs_indemnite = findrub_user.filter(item => item.user_code === element.code && item.type === 'indemnite');
                            updatedelement.rubs_retenue = findrub_user.filter(item => item.user_code === element.code && item.type === 'retenue');
                            updatedelement.avantage_fiscaux = findrub_user.filter(item => item.user_code === element.code && item.type === 'avantage_fiscaux');
                            updatedelement.avantages = find_avantage
                            updatedelement = calculbrut(updatedelement)
                            
                                console.log('updatedelement,',updatedelement)
                            return updatedelement;
                        }






                        //  return updatedelement;
                    });

                    setdata_map(listuser.filter(item => item !== undefined));
                    setloading(false)
                }

            })

        }
    }
    useEffect(()=>{
        if (prime && prime.libelle === "13eme") {
            getpaie_yearallUsers(year).then((res) => {
                console.log('res.data',res.data)
                setlastpaies(res.data)
            })
        }
    },[])
    useEffect(() => {
        calcul_paie()
    }, [data, param_paie_active,lastpaies, irpp, listpaie, lastpaie, user_selected,exercice_precedent])

  
    const getnombrejourmois = (jsonObject) => {
        const convertedObject = {};
        for (const key in jsonObject) {
            const newKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            convertedObject[newKey] = jsonObject[key];
        }
        const lemois = listmois[parseInt(dateP.split('-')[1]) - 1]

        const nbrjtravaille = convertedObject[lemois]
        return nbrjtravaille

    }
    const CalculSalaire = (nbjr_travaille, salaire_journee) => {


        const salaire_base_calculer = parseFloat((salaire_journee * nbjr_travaille)) || 0
        return { salaire_journee: salaire_journee, salaire_base_calculer: arondisnumber(salaire_base_calculer, 3) }
    }
    const calculbrut = (updatedelement) => {

        updatedelement.total_rub = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                } else {
                    return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };

        }, 0) || 0;

        updatedelement.total_retenue = updatedelement.rubs_retenue?.reduce((acc, curr) => {
            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                } else {
                    return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };
        }, 0) || 0;

        updatedelement.total_av = updatedelement.avantages?.reduce((acc, curr) => {
            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s) * updatedelement.total);
                } else {
                    return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / updatedelement.nb_s));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };
        }, 0) || 0;

        updatedelement.total_rub_cnss = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
            if (curr.cnss === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + (curr.valeur_annuel / updatedelement.nb_s);
                } else {
                    if (curr.presence === 1) {

                        return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);

                    } else {
                        return acc + curr.valeur_mensuel;

                    }
                }


            }
            else {
                return acc + 0;

            }

        }, 0);


        updatedelement.total_rub_non_irpp = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
            if (curr.irpp === 0) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + (curr.valeur_annuel / updatedelement.nb_s);
                } else {

                    if (curr.presence === 1) {

                        return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);

                    } else {
                        return acc + curr.valeur_mensuel;

                    }
                    //  return acc + curr.valeur_mensuel;
                }

            }
            else {
                return acc + 0;

            }
        }, 0);
        updatedelement.total_rub_irpp = updatedelement.rubs_indemnite?.reduce((acc, curr) => {
            if (curr.irpp === 1) {

                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + (curr.valeur_annuel / updatedelement.nb_s);
                } else {
                    if (curr.presence === 1) {

                        return acc + ((curr.valeur_mensuel / updatedelement.nbrjtravaille) * updatedelement.total);

                    } else {
                        return acc + curr.valeur_mensuel;

                    }
                    // return acc + curr.valeur_mensuel;
                }


            }
            else {
                return acc + 0;

            }
        }, 0);


        updatedelement.total_avantage_fiscaux = updatedelement.avantage_fiscaux?.reduce((acc, curr) => {

            if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                return acc + curr.valeur_annuel;
            } else {
                return acc + (curr.valeur_mensuel * updatedelement.nb_s);
            }
        }, 0);





        updatedelement.totalenfant = 0
        if (updatedelement.enfant >= 1 && param_paie_active.enfant_1er === 1) {

            updatedelement.totalenfant += param_paie_active.val_enfant_1er

        }
        if (updatedelement.enfant >= 2 && param_paie_active.enfant_2eme === 1) {
            updatedelement.totalenfant += param_paie_active.val_enfant_2eme

        }
        if (updatedelement.enfant >= 3 && param_paie_active.enfant_3eme === 1) {
            updatedelement.totalenfant += param_paie_active.val_enfant_3eme

        }
        if (updatedelement.enfant >= 4 && param_paie_active.enfant_4eme === 1) {
            updatedelement.totalenfant += param_paie_active.val_enfant_4eme

        }
        updatedelement.totalenfant_h = 0;
        if (updatedelement.enfant_handicape >= 1 && param_paie_active.Enfant_handicape === 1) {
            updatedelement.totalenfant_h = updatedelement.enfant_handicape * param_paie_active.val_Enfant_handicape
        }
        updatedelement.totalenfantN_b = 0
        if (updatedelement.enfant_N_B >= 1 && param_paie_active.etudiant_N_B === 1) {
            updatedelement.totalenfantN_b = updatedelement.enfant_N_B * param_paie_active.val_etudiant_N_B
        }
        updatedelement.totalparent = 0

        if (updatedelement.Parent_charge === 1 && param_paie_active.Parent_charge === 1) {
            updatedelement.totalparent = param_paie_active.val_Parent_charge
        }
        updatedelement.tauxchef_famille = 0
        if (updatedelement.chef_famille === 1 && param_paie_active.chef_famille === 1) {
            updatedelement.tauxchef_famille = param_paie_active.val_chef_famille

        }


        updatedelement.commission = updatedelement.total_av + updatedelement.total_rub

        updatedelement.difference = lastpaie.find(item => item.id_users === updatedelement.user_code_id)?.difference || 0
        //  if(updatedelement.difference !== 0){alert( updatedelement.difference )}
        updatedelement.total_brut = updatedelement.salaire_base_calculer + arondisnumber(updatedelement.commission, 3);

        updatedelement.brut_cnss = updatedelement.salaire_base_calculer + updatedelement.total_rub_cnss;
        updatedelement.rs_cnss = 0;


        if (param_paie_active.cnss === 1 && updatedelement.tc_cnss === 1 && updatedelement.fonction !== 'gerant') {

            updatedelement.rs_cnss = updatedelement.brut_cnss * (param_paie_active.val_cnss / 100)

        }


     

        if (updatedelement.tc_irpp && updatedelement.tc_irpp !== 0) {

            updatedelement.salaire_imposable = arondisnumber(updatedelement.total_brut - updatedelement.rs_cnss, 3)


            updatedelement.salaire_ans_imposable = arondisnumber((updatedelement.salaire_imposable * updatedelement.nb_s), 3) || 0;
            updatedelement.moindixp = updatedelement.salaire_ans_imposable;
    
            if (param_paie_active.dix_p === 1) {
    
                const moindixp = updatedelement.salaire_ans_imposable * (param_paie_active.val_dix_p / 100);
    
                if (moindixp > param_paie_active.montant_dix_p) {
                    updatedelement.moindixp = updatedelement.salaire_ans_imposable -  param_paie_active.montant_dix_p
                } else {
                    updatedelement.moindixp = updatedelement.salaire_ans_imposable - moindixp
                }
            }
            updatedelement.net = Math.ceil(updatedelement.moindixp - updatedelement.total_rub_non_irpp - updatedelement.totalenfant - updatedelement.totalenfant_h - updatedelement.totalenfantN_b - updatedelement.totalparent - updatedelement.tauxchef_famille - updatedelement.total_avantage_fiscaux);
            const res_irpp = calcul_irpp(param_paie_active,irpp,updatedelement.net, updatedelement.nb_s);
            const  findrepotImpot = listReportImpot.find(item=>item.id_user===parseInt(updatedelement.user_id))
            var irpp_mois ,css_mois,resterepotirpp,resterepotcss

            if(findrepotImpot){
                console.log('findrepotImpot',findrepotImpot)

                updatedelement.irpp_report=findrepotImpot.irpp
                updatedelement.css_report=findrepotImpot.css
                 irpp_mois= res_irpp.calcul_irpp_mois-findrepotImpot.irpp > 0 ?res_irpp.calcul_irpp_mois-findrepotImpot.irpp:0
                 css_mois= (res_irpp.calcul_irpp_css_mois-res_irpp.calcul_irpp_mois)-findrepotImpot.css > 0 ?res_irpp.calcul_irpp_mois-findrepotImpot.irpp:0

                 resterepotirpp = findrepotImpot.irpp-res_irpp.calcul_irpp_mois > 0 ? findrepotImpot.irpp-res_irpp.calcul_irpp_mois :0
                 resterepotcss = findrepotImpot.css-(res_irpp.calcul_irpp_css_mois-res_irpp.calcul_irpp_mois) >0?findrepotImpot.css-(res_irpp.calcul_irpp_css_mois-res_irpp.calcul_irpp_mois):0
                const update_report_impot = {id_user:parseInt(updatedelement.user_id),irpp:resterepotirpp,css:resterepotcss ,irpp_css:resterepotcss+resterepotirpp ,exercice:exercice_precedent}
                setlistupdateReport((prev)=>[...prev ,update_report_impot])
                }
       

            updatedelement.irpp_fiche= res_irpp.calcul_irpp_mois;
            updatedelement.css_fiche=res_irpp.calcul_irpp_css_mois-res_irpp.calcul_irpp_mois;
            updatedelement.calcul_irpp_css_mois = findrepotImpot ?css_mois+irpp_mois:res_irpp.calcul_irpp_css_mois
            updatedelement.calcul_irpp_mois = findrepotImpot ?irpp_mois:res_irpp.calcul_irpp_mois;
            updatedelement.montantcss = arondisnumber((updatedelement.calcul_irpp_css_mois - updatedelement.calcul_irpp_mois), 3)
            updatedelement.salaire_net = arondisnumber(((updatedelement.salaire_imposable || 0) - updatedelement.calcul_irpp_mois - updatedelement.montantcss), 3);

            updatedelement.salaire_irpp = arondisnumber(((updatedelement.salaire_imposable || 0) - updatedelement.calcul_irpp_mois), 3);

            updatedelement.unp = arondisnumber((updatedelement.calcul_irpp_css_mois - updatedelement.calcul_irpp_mois), 3)
            updatedelement.net_servi = arondisnumber((updatedelement.salaire_net - updatedelement.total_retenue), 3)
            updatedelement.net_servi_avant_arrondi = updatedelement.net_servi

        } else {

            updatedelement.net_servi = updatedelement.total_brut
            updatedelement.salaire_net = updatedelement.total_brut

            updatedelement.net_servi_avant_arrondi = updatedelement.total_brut
        }
        if (param_paie_active.arondi_net === 1) {
            const arrondi_net_servi = arrondi_entier(updatedelement.net_servi_avant_arrondi, updatedelement.difference)
            updatedelement.net_servi = arrondi_net_servi.newvaleur;
            updatedelement.difference = arrondi_net_servi.valeur_difference;
        } else {
            // updatedelement.net_servi = arrondi_net_servi.newvaleur;
            updatedelement.difference = 0;
        }
        return updatedelement;
    }


    const onclickshow = (index) => {
        setclick({
            clicked: true,
            index: index,

        })
        if (data_map[index].cloturer !== 1) {
            setchange(true)

        }

    }
    const save = (data) => {
        swal({
            title: t("Ëtes-vous sûr ?"),
            text: t("Voulez-vous enregistrer le paie "),
            icon: "warning",
            buttons: {
                Annuler: t("Annuler"),
                confirm: {
                    text: t("OK"),
                    value: true,
                },
            },
            dangerMode: true,
        }).then((res) => {
            if (res !== "Annuler") {
                var s
                if (prime?.moisplus) {
                    s = 'moisplus'
                } else if (prime?.prime) {
                    s = 'prime'
                } else {
                    s = 'mois'
                }
                const data_entet = data.map((item) => {

                    return {
                        id_users: item.user_id,
                        code_paie: item?.code_paie,
                        annee: dateP.split('-')[0],
                        code_generated: localStorage.getItem('code_generated'),
                        mois: listmois[parseInt(dateP.split('-')[1]) - 1],
                        exercice: param_paie_active.exercice,
                        salaire_base: item.salaire_base,
                        salaire_base_mois: item.salaire_base_calculer,
                        indemnites: item.commission,
                        salaire_brut: item.total_brut,
                        retenue_cnss: item.rs_cnss,
                        salaire_imposable: item.salaire_imposable,
                        salaire_ans_imposable: item.salaire_ans_imposable,
                        calcul_irpp_mois: item.calcul_irpp_mois,
                        calcul_irpp_css_mois: item.calcul_irpp_css_mois,
                        salaire_net: item.salaire_net,
                        total_retenue: item.total_retenue,
                        net_a_payer: item.net_servi,
                        jour_presence: item.total_present,
                        total: item.total,
                        nbj_travaille: item.nbrjtravaille,
                        enfant: item.enfant,
                        totalenfant: item.totalenfant,
                        enfant_handicape: item.enfant_handicape,
                        totalenfant_h: item.totalenfant_h,
                        enfant_N_B: item.enfant_N_B,
                        totalenfantN_b: item.totalenfantN_b,
                        Parent_charge: item.Parent_charge,
                        totalparent: item.totalparent,
                        chef_famille: item.chef_famille,
                        tauxchef_famille: item.tauxchef_famille,
                        nb_s: item.nb_s,
                        tc_irpp: item.tc_irpp,
                        tc_cnss: item.tc_cnss,
                        net_impo: item.net,
                        difference: item.difference,
                        net_servi_avant_arrondi: item.net_servi_avant_arrondi,
                        correction_salaire_base: data_change.find(e => e.userid === item.user_id && e.name === 'salaire_base')?.oldvalue || null,

                        correction_jour_presence: data_change.find(e => e.userid === item.user_id && e.name === 'total_present')?.oldvalue || null,
                        jour_absence: item.total_Ab,
                        correction_jour_absence: data_change.find(e => e.userid === item.user_id && e.name === 'Njr_Ab')?.oldvalue || null,
                        jour_conge: item.total_conge,
                        correction_jour_conge: data_change.find(e => e.userid === item.user_id && e.name === 'total_conge')?.oldvalue || null,
                        jour_ferier_paye: item.total_jr_ferire,
                        list_indemnite: item.rubs_indemnite,
                        list_avantages: item.avantages,
                        list_retenue: item.rubs_retenue,
                        list_avantage_fiscaux: item.avantage_fiscaux,
                        heure_supp_t1: item.heure_supp_t1,
                        heure_supp_t2: item.heure_supp_t2,
                        h_sup_t1: item.h_sup_t1,
                        h_sup_t2: item.h_sup_t2,
                        total_supp_t1: item.total_supp_t1,
                        total_supp_t2: item.total_supp_t2,
                        Nbr_h_jr: item.Nbr_h_jr,
                        salaire_heure_t1: item.salaire_heure_t1,
                        salaire_heure_t2: item.salaire_heure_t2,
                        listdelete: item.listdelete,
                        regule_impo: item.regule_impo,
                        soldeconge: item.soldeconge,
                        acquisconge: item.acquisconge,
                        servisconge: item.servisconge,
                        diffconge: item.difconge,
                        status: s,
                        prime: prime?.libelle,
                        exercice_precedent:exercice_precedent,
                        irpp_report:item.irpp_report,
                        css_report:item.css_report,
                        irpp_fiche:item.irpp_fiche,
                        css_fiche:item.css_fiche

                    }
                })
                const dataaddPaie={data:data_entet , listReportImpot:listupdateReport}

                addPaie(dataaddPaie).then((resadd) => {
                    if (resadd.status === 201) {
                        toast.success(t('Le paie est enregistré avec succès'))
                        const copie_map = [...data_map]
                        console.log('data', resadd.data.data)
                        data.forEach(element => {
                            const findindex = data_map.findIndex(item => item.user_id === element.user_id)
                            if (findindex !== -1) {
                                const finditem = resadd.data.data.insertpaie.find(i => i.id_users === element.user_id)
                                if (finditem) {
                                    copie_map[findindex].code_paie = finditem.code
                                    copie_map[findindex].saved = 1
                                }

                            }

                        });

                        setdata_map(copie_map)
                    } else {
                        toast.error(t('Un problème '))

                    }
                })
            }
        });


    }
    const save_cloturer = (datasave) => {
        const findnonpaie = data.find(item => !data_map.map(e => parseInt(e.user_code_id)).includes(parseInt(item.user_code_id)))


        swal({
            title: t("Ëtes-vous sûr ?"),
            text: t(findnonpaie ? `Voulez-vous enregistrer  et clôturer le paie  Même s'il y a des employés actifs non paie` : "Voulez-vous enregistrer  et clôturer le paie"),
            icon: "warning",
            buttons: {
                Annuler: t("Annuler"),
                confirm: {
                    text: t("OK"),
                    value: true,
                },
            },
            dangerMode: true,
        }).then((res) => {

            if (res !== 'Annuler') {
                var s
                if (prime?.moisplus) {
                    s = 'moisplus'
                } else if (prime?.prime) {
                    s = 'prime'
                } else {
                    s = 'mois'
                }
                const data_entet = datasave.map((item) => {

                    return {
                        id_users: item.user_id,
                        code_paie: item?.code_paie,
                        annee: dateP.split('-')[0],
                        code_generated: localStorage.getItem('code_generated'),
                        mois: listmois[parseInt(dateP.split('-')[1]) - 1],
                        exercice: param_paie_active.exercice,
                        salaire_base: item.salaire_base,
                        salaire_base_mois: item.salaire_base_calculer,
                        indemnites: item.commission,
                        salaire_brut: item.total_brut,
                        retenue_cnss: item.rs_cnss,
                        salaire_imposable: item.salaire_imposable,
                        salaire_ans_imposable: item.salaire_ans_imposable,
                        calcul_irpp_mois: item.calcul_irpp_mois,
                        calcul_irpp_css_mois: item.calcul_irpp_css_mois,
                        salaire_net: item.salaire_net,
                        total_retenue: item.total_retenue,
                        net_a_payer: item.net_servi,
                        jour_presence: item.total_present,
                        total: item.total,
                        nbj_travaille: item.nbrjtravaille,
                        enfant: item.enfant,
                        totalenfant: item.totalenfant,
                        enfant_handicape: item.enfant_handicape,
                        totalenfant_h: item.totalenfant_h,
                        enfant_N_B: item.enfant_N_B,
                        totalenfantN_b: item.totalenfantN_b,
                        Parent_charge: item.Parent_charge,
                        totalparent: item.totalparent,
                        chef_famille: item.chef_famille,
                        tauxchef_famille: item.tauxchef_famille,
                        nb_s: item.nb_s,
                        tc_irpp: item.tc_irpp,
                        tc_cnss: item.tc_cnss,
                        net_impo: item.net,
                        difference: item.difference,
                        net_servi_avant_arrondi: item.net_servi_avant_arrondi,
                        correction_salaire_base: data_change.find(e => e.userid === item.user_id && e.name === 'salaire_base')?.oldvalue || null,

                        correction_jour_presence: data_change.find(e => e.userid === item.user_id && e.name === 'total_present')?.oldvalue || null,
                        jour_absence: item.total_Ab,
                        correction_jour_absence: data_change.find(e => e.userid === item.user_id && e.name === 'Njr_Ab')?.oldvalue || null,
                        jour_conge: item.total_conge,
                        correction_jour_conge: data_change.find(e => e.userid === item.user_id && e.name === 'total_conge')?.oldvalue || null,
                        jour_ferier_paye: item.total_jr_ferire,
                        list_indemnite: item.rubs_indemnite,
                        list_avantages: item.avantages,
                        list_retenue: item.rubs_retenue,
                        list_avantage_fiscaux: item.avantage_fiscaux,
                        heure_supp_t1: item.heure_supp_t1,
                        heure_supp_t2: item.heure_supp_t2,
                        h_sup_t1: item.h_sup_t1,
                        h_sup_t2: item.h_sup_t2,
                        total_supp_t1: item.total_supp_t1,
                        total_supp_t2: item.total_supp_t2,
                        Nbr_h_jr: item.Nbr_h_jr,
                        salaire_heure_t1: item.salaire_heure_t1,
                        salaire_heure_t2: item.salaire_heure_t2,
                        listdelete: item.listdelete,
                        regule_impo: item.regule_impo,
                        soldeconge: item.soldeconge,
                        acquisconge: item.acquisconge,
                        servisconge: item.servisconge,
                        diffconge: item.difconge,
                        status: s,
                        prime: prime?.libelle,
                        exercice_precedent:exercice_precedent,
                        irpp_report:item.irpp_report,
                        css_report:item.css_report,
                        irpp_fiche:item.irpp_fiche,
                        css_fiche:item.css_fiche

                    }
                })
                    const dataaddPaie={data:data_entet , listReportImpot:listupdateReport}

                addPaie(dataaddPaie).then((res) => {
                    if (res.status === 201) {
                        
                        const mois = listmois[parseInt(dateP.split('-')[1]) - 1];
                        const year = dateP.split('-')[0];
                        const data_cloture = { mois: mois, year: year, status: s, prime: prime?.libelle, cloture: 1, code_generated: localStorage.getItem('code_generated') }
                        cloturerpaie(data_cloture).then((rescloturer) => {
                            if (rescloturer.status === 200) {
                                toast.success(t("Le paiement a été enregistré et clôturé"))
                                window.location.reload()

                            } else {
                                toast.error(t('Un problème '))

                            }

                        })
                    } else {
                        toast.error(t('Un problème '))

                    }
                })
            }
        });
    }
    const save_cloturer_ligne = (index) => {

        const clotureval = data_map[index].cloturer === 1 ? 0 : 1
        const userid = data_map[index].user_id
        var s
        if (prime?.moisplus) {
            s = 'moisplus'
        } else if (prime?.prime) {
            s = 'prime'
        } else {
            s = 'mois'
        }
        const data_entet = data_map.map((item) => {

            return {
                id_users: item.user_id,
                code_paie: item?.code_paie,
                annee: dateP.split('-')[0],
                code_generated: localStorage.getItem('code_generated'),
                mois: listmois[parseInt(dateP.split('-')[1]) - 1],
                exercice: param_paie_active.exercice,
                salaire_base: item.salaire_base,
                salaire_base_mois: item.salaire_base_calculer,
                indemnites: item.commission,
                salaire_brut: item.total_brut,
                retenue_cnss: item.rs_cnss,
                salaire_imposable: item.salaire_imposable,
                salaire_ans_imposable: item.salaire_ans_imposable,
                calcul_irpp_mois: item.calcul_irpp_mois,
                calcul_irpp_css_mois: item.calcul_irpp_css_mois,
                salaire_net: item.salaire_net,
                total_retenue: item.total_retenue,
                net_a_payer: item.net_servi,
                jour_presence: item.total_present,
                total: item.total,
                nbj_travaille: item.nbrjtravaille,
                enfant: item.enfant,
                totalenfant: item.totalenfant,
                enfant_handicape: item.enfant_handicape,
                totalenfant_h: item.totalenfant_h,
                enfant_N_B: item.enfant_N_B,
                totalenfantN_b: item.totalenfantN_b,
                Parent_charge: item.Parent_charge,
                totalparent: item.totalparent,
                chef_famille: item.chef_famille,
                tauxchef_famille: item.tauxchef_famille,
                nb_s: item.nb_s,
                tc_irpp: item.tc_irpp,
                tc_cnss: item.tc_cnss,
                net_impo: item.net,
                difference: item.difference,
                net_servi_avant_arrondi: item.net_servi_avant_arrondi,
                correction_salaire_base: data_change.find(e => e.userid === item.user_id && e.name === 'salaire_base')?.oldvalue || null,

                correction_jour_presence: data_change.find(e => e.userid === item.user_id && e.name === 'total_present')?.oldvalue || null,
                jour_absence: item.total_Ab,
                correction_jour_absence: data_change.find(e => e.userid === item.user_id && e.name === 'Njr_Ab')?.oldvalue || null,
                jour_conge: item.total_conge,
                correction_jour_conge: data_change.find(e => e.userid === item.user_id && e.name === 'total_conge')?.oldvalue || null,
                jour_ferier_paye: item.total_jr_ferire,
                list_indemnite: item.rubs_indemnite,
                list_avantages: item.avantages,
                list_retenue: item.rubs_retenue,
                list_avantage_fiscaux: item.avantage_fiscaux,
                heure_supp_t1: item.heure_supp_t1,
                heure_supp_t2: item.heure_supp_t2,
                h_sup_t1: item.h_sup_t1,
                h_sup_t2: item.h_sup_t2,
                total_supp_t1: item.total_supp_t1,
                total_supp_t2: item.total_supp_t2,
                Nbr_h_jr: item.Nbr_h_jr,
                salaire_heure_t1: item.salaire_heure_t1,
                salaire_heure_t2: item.salaire_heure_t2,
                listdelete: item.listdelete,
                regule_impo: item.regule_impo,
                soldeconge: item.soldeconge,
                acquisconge: item.acquisconge,
                servisconge: item.servisconge,
                diffconge: item.difconge,
                status: s,
                prime: prime?.libelle,
                exercice_precedent:exercice_precedent,
                irpp_report:item.irpp_report,
                css_report:item.css_report,
                irpp_fiche:item.irpp_fiche,
                css_fiche:item.css_fiche

            }
        })

        const dataaddpaie={data:data_entet , listReportImpot:listupdateReport}

        addPaie(dataaddpaie).then((res) => {
            if (res.status === 201) {
                const mois = listmois[parseInt(dateP.split('-')[1]) - 1];
                const year = dateP.split('-')[0];
                const data_cloture = { mois: mois, year: year, status: s, prime: prime?.libelle, userid: userid, cloture: clotureval, code_generated: localStorage.getItem('code_generated') }
                cloturerpaie_id(data_cloture).then((rescloturer) => {
                    if (rescloturer.status === 200) {
                        setlistpaie(rescloturer.data.data)

                        const copie_data = [...data_map]

                        copie_data[index].cloturer = clotureval;
                        setdata_map(copie_data)
                        const list_user = copie_data.map(item => parseInt(item.user_code))

                        //  setuser_selected(list_user)
                        toast.success(t(`la ligne est ${clotureval === 1 ? 'cloturé' : 'décloturé'} `))



                    } else {
                        toast.error(t('Un problème '))

                    }

                })
            } else {
                toast.error(t('Un problème '))

            }
        })

    }

    const get_fiche_paie = (index) => {

        setclick({
            clicked: false,
            index: index,

        })
        setfiche(true)
        const employee = data_map[index]
        const mois = listmois[parseInt(mois_selected)]

        const data_employee = {
            user_id: employee.user_id,
            matricule_user: employee?.matricule_user,
            nom: employee.nom,
            prenom: employee.prenom,
            salaire_base_calculer: employee.salaire_base_calculer,
            cnss: employee.cnss,
            matricule: '',
            Qualification: employee.fonction,
            mois: mois,
            annee: employee.annee,
            situation_famille: employee.situation_familiale,
            chef_famille: employee.chef_famille,
            totalenfant: employee.totalenfant,
            Parent_charge: employee.Parent_charge,
            categorie: '',
            echelle: '',
            echelon: '',
            CA: employee.total_conge,
            CB: "",
            FM: '',
            JF: employee.total_jr_ferire,
            AB: employee.total_Ab,
            PR: employee.total_present,
            JT: employee.nbrjtravaille,
            NH: "",
            TH: '',
            total: employee.total,
            salaire_retenue: employee.salaire_retenue,
            net_a_payer: employee.net_servi,
            salaire_base: employee.salaire_base,
            salaire_journee: parseFloat(employee.salaire_journee.toFixed(3)),
            rubs_indemnite: employee.rubs_indemnite,
            rubs_retenue: employee.rubs_retenue,
            avantages: employee.avantages,
            total_brut: employee.total_brut,
            rs_cnss: employee.rs_cnss,
            salaire_imposable: employee.salaire_imposable,
            calcul_irpp_mois: employee.calcul_irpp_mois,
            unp: employee.unp,
            total_retenue: employee.total_retenue + employee.salaire_retenue,
            date_embauche: employee.date_embauche,
            taux_cnss: employee.taux_cnss,
            brut_cnss: employee.brut_cnss,
            CP: employee.CP,
            date: dateP,
            soldeconge: employee.soldeconge,
            servisconge: employee.servisconge,
            acquisconge: employee.acquisconge,
            rib: employee.rib,




        }
        setdata_employee(data_employee)
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });

    }
    const downloadPDF = () => {
        // Find the active tab pane
        const activeTabPane = document.querySelector('.tab-pane.active');

        if (activeTabPane) {
            // Use html2canvas to capture the content of the active tab pane
            html2canvas(activeTabPane)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    const imgWidth = 210; // A4 size width in mm
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                    pdf.save('fiche_paie_.pdf');
                });
        } else {
            console.error('No active tab pane found.');
        }
    };


    const downloadallPDF = async () => {
        const mois = listmois[parseInt(mois_selected)];

        for (let i = 0; i < data_map.length; i++) {
            const employee = data_map[i];

            if (employee.saved && employee.saved === 1) {

                setloading(true)
                const data_employee = {
                    user_id: employee.user_id,
                    nom: employee.nom,
                    prenom: employee.prenom,
                    cnss: employee.cnss,
                    matricule: '',
                    Qualification: employee.fonction,
                    mois: mois,
                    situation_famille: employee.situation_familiale,
                    categorie: '',
                    echelle: '',
                    echelon: '',
                    CA: employee.total_conge,
                    CB: "",
                    FM: '',
                    JF: employee.total_jr_ferire,
                    AB: employee.total_Ab,
                    PR: employee.total_present,
                    JT: employee.nbrjtravaille,
                    NH: "",
                    TH: '',
                    total: employee.total,
                    salaire_retenue: employee.salaire_retenue,
                    net_a_payer: employee.net_servi,
                    salaire_base: employee.salaire_base,
                    salaire_journee: employee.salaire_journee,
                    rubs_indemnite: employee.rubs_indemnite,
                    rubs_retenue: employee.rubs_retenue,
                    avantages: employee.avantages,
                    total_brut: employee.total_brut,
                    rs_cnss: employee.rs_cnss,
                    salaire_imposable: employee.salaire_imposable,
                    calcul_irpp_mois: employee.calcul_irpp_mois,
                    unp: employee.unp,
                    total_retenue: employee.total_retenue + employee.salaire_retenue
                };

                // Determine the active tab
                const activeTabPane = document.querySelector('.tab-pane.active');
                if (!activeTabPane) {
                    console.error('No active tab pane found.');
                    return;
                }
                // alert(activeTabPane.id)

                // Render the appropriate component based on the active tab
                if (activeTabPane.id === 'tab5') {
                    ReactDOM.flushSync(() => {
                        ReactDOM.render(<FichepaieModel2 t={t} employeeData={data_employee} />, document.getElementById('content'));
                    });

                }
                else if (activeTabPane.id === 'tab6') {
                    ReactDOM.flushSync(() => {
                        ReactDOM.render(<Fichepaie t={t} employeeData={data_employee} />, document.getElementById('content'));
                    });
                } else {
                    console.warn('Unknown tab ID:', activeTabPane.id);
                }

                // Allow the DOM to update
                await new Promise(resolve => setTimeout(resolve, 10000));

                try {
                    const input = document.getElementById('content');
                    if (input) {
                        const canvas = await html2canvas(input);
                        const imgData = canvas.toDataURL('image/png');
                        if (!imgData) {
                            throw new Error('Failed to capture canvas as PNG');
                        }

                        const pdf = new jsPDF();
                        const imgWidth = 210; // Standard A4 width in mm
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                        pdf.save(`fiche_paie_${employee.id}.pdf`); // Save with unique name based on employee ID or other unique attribute
                    } else {
                        console.error('Content element not found.');
                    }
                } catch (error) {
                    console.error('Error capturing PDF:', error);
                }

                // Clean up the DOM for the next iteration
                ReactDOM.unmountComponentAtNode(document.getElementById('content'));
                setloading(false)
            }
        }
    };

    const removeItem = async (index) => {
        const copie_data = [...data_map]
        const copie_selected = user_selected.length !== 0 ? [...user_selected] : []

        copie_data.splice(index, 1);
        const findindex = user_selected.length !== 0 ? user_selected.findIndex(item => item === data_map[index]?.user_id) : data.findIndex(item => item === data_map[index]?.user_id)

        if (findindex !== -1) {
            let todelete = true;
            if (data_map[index].code_paie) {
                const res = await deletelignepaie(data_map[index].code_paie)
                todelete = res.status === 200 ? true : false

            }
            if (todelete) {
                copie_selected.splice(findindex, 1);
                const data_selected = data.filter(item => item.user_id !== data_map[index]?.user_id)
                const list_user = data_selected?.map(item => parseInt(item.user_id));


                copie_selected.length !== 0 ? setuser_selected(copie_selected) : setuser_selected(list_user)



            }



        }


    }

    const reguleimpo = async (item) => {
        // console.log(item);
        const year = dateP.split('-')[0];


        const response = await getpaie_year("2024", item.user_id);
        if (response.status === 200) {
            const listpaieyear = response.data


            const total_imposable = Array.isArray(listpaieyear) ? listpaieyear?.reduce((acc, curr) => acc + (parseFloat(curr.salaire_imposable) || 0), 0) : 0;

            const total_irpp = Array.isArray(listpaieyear) ? listpaieyear?.reduce((acc, curr) => acc + (parseFloat(curr.calcul_irpp_mois) || 0), 0) : 0;

            const total_irpp_css = Array.isArray(listpaieyear) ? listpaieyear?.reduce((acc, curr) => acc + (parseFloat(curr.calcul_irpp_css_mois) || 0), 0) : 0;
            const total_css = total_irpp_css - total_irpp;

            const mois_servis = listpaieyear?.length || 0;
            const mois_reste = 1
            const imposable_servis= parseFloat(total_imposable)/item.nb_s 
            const new_impo = parseFloat(imposable_servis) + total_imposable;

            let new_impo_moindixp = new_impo;
            if (param_paie_active.dix_p === 1) {
                const moindixp = new_impo * (param_paie_active.val_dix_p / 100);
                new_impo_moindixp = moindixp > param_paie_active.montant_dix_p ? new_impo - param_paie_active.montant_dix_p : new_impo - moindixp;
            }



            const net = new_impo_moindixp - item.total_rub_non_irpp - item.totalenfant - item.totalenfant_h - item.totalenfantN_b - item.totalparent - item.tauxchef_famille - item.total_avantage_fiscaux;
                console.log('netttt',net)

            const res_irpp = calcul_irpp(net, item.nb_s);
            console.log('res_irpp',res_irpp)

            const new_calcul_irpp_css = res_irpp.calcul_irpp_css_annuel;
            const new_calcul_irpp = res_irpp.calcul_irpp_annuel;
            const new_calcul_css = new_calcul_irpp_css - new_calcul_irpp;

            const irpp_reste = new_calcul_irpp - total_irpp;
            const css_reste = new_calcul_css - total_css;
            const irpp = irpp_reste / mois_reste;
            const css = css_reste / mois_reste;
            const irpp_css = irpp + css;

            const copie = [...data_map];
            const index = copie.findIndex(e => e.user_id === item.user_id);

            if (index !== -1) {
                copie[index].calcul_irpp_css_mois = float_decimale(irpp_css, 3);
                copie[index].calcul_irpp_mois = float_decimale(irpp, 3);

                copie[index].montantcss = float_decimale(css, 3);
                copie[index].salaire_net = arondisnumber(((copie[index].salaire_imposable || 0) - copie[index].calcul_irpp_mois - copie[index].montantcss), 3);
                copie[index].salaire_irpp = arondisnumber(((copie[index].salaire_imposable || 0) - copie[index].calcul_irpp_mois), 3);
                copie[index].unp = arondisnumber((copie[index].salaire_irpp - copie[index].salaire_net), 3);
                copie[index].net_servi = arondisnumber((copie[index].salaire_net - copie[index].total_retenue), 3);
                copie[index].net_servi_avant_arrondi = copie[index].net_servi;
                copie[index].regule_impo = 1;

                if (param_paie_active.arondi_net === 1) {
                    const arrondi_net_servi = arrondi_entier(copie[index].net_servi_avant_arrondi, copie[index].difference);
                    copie[index].net_servi = arrondi_net_servi.newvaleur;
                    copie[index].difference = arrondi_net_servi.valeur_difference;
                } else {
                    copie[index].difference = 0;
                }

                setdata_map(copie);
            }

        }

    }
    const regule_impo_all = async (data_regule) => {


        for (const element of data_regule) {
            await reguleimpo(element); // Wait for the promise to resolve
        }
        setlist_regule([])
        setregule_impo(false)


    }

    const select_regule = async (user) => {
        //console.log(user)
        const findindexuser = list_regule.findIndex(item => item.user_id === user.user_id);
        //  alert(findindexuser)
        if (findindexuser !== -1) {
            const list = [...list_regule];
            list.splice(findindexuser, 1);
            setlist_regule(list);
        } else {

            setlist_regule((prev) => [...prev, user])

        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label className="form-label">{t("Exercice")}: {exercice?.find(item => item.id === exercice_selectionne)?.code_exercice}
                                        </label>
                                    </div>

                                </div>
                                {prime?.prime ? (
                                    <><div className="col-2 col-lg-2">
                                        <div className="form-group">
                                            <strong className="form-label">{prime.moisplus ? t("") : t("Prime:")}   {prime?.libelle} </strong>

                                        </div>
                                    </div><div className="col-2 col-lg-2">
                                            <div className="form-group">
                                                <strong className="form-label">{t("Date ")}:   {props?.location.state?.date} </strong>

                                            </div>
                                        </div></>
                                ) : <div className="col-3 col-lg-3">
                                    <div className="form-group">
                                        <label className="form-label">{t("Choisir le mois")}:   <select className="form-control" value={mois_selected} onChange={(e) => handleChangeDate(e.target.value)}>
                                            {listmois.map((item, index) => (
                                                <option value={index} key={index}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                        </label>



                                    </div>
                                </div>}

                                <div className='col-2'>
                                    <div className="btn btn-primary float-md-right" onClick={() => window.location.reload()}><IoRefresh />{t('Actualisé')}</div>

                                </div>
                                <div className='col-2'>
                                    {(!regule_impo && list_regule.reduce((acc, curr) => {
                                        return curr.regule_impo === 1 ? acc + 1 : acc;
                                    }, 0) !== data_map.length) &&
                                        < div className="btn btn-primary float-md-right" onClick={() => setregule_impo(true)}>{t("Regule d'impo")}</div>}
                                    {regule_impo && <div className="btn btn-primary float-md-right" onClick={() => regule_impo_all(list_regule)}>{t("Calculer le regule")}</div>}

                                </div>
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="table-responsive hr-attlist">

                                    <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                                        <thead>
                                            {regule_impo && <th></th>}
                                            <th></th>
                                            <th className="border-bottom-0">
                                                <strong>{t('NOM PRENOM')}</strong>
                                            </th>

                                            <th className="border-bottom-0">
                                                <strong>{t(' SALAIRE DE BASE')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t('INDEMNITES')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' SALAIRE BRUT')}</strong>
                                            </th>

                                            <th className="border-bottom-0">
                                                <strong>{t(' RETENUE CNSS')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' SALAIRE IMOSBLE')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' IRPP')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t('SALAIRE IRPP')}</strong>
                                            </th>
                                            <th> <strong>{t('CSS')}</strong></th>
                                            <th className="border-bottom-0">
                                                <strong> {t(' NET')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' RETENUE')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' Net servi')}</strong>
                                            </th>

                                            <th className="border-bottom-0">
                                                <strong>{t(' arrondi')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t(' Net servi arrondi')}</strong>
                                            </th>
                                            <th className="border-bottom-0">
                                                <strong>{t('Etat')}</strong>
                                            </th>

                                            <th className="border-bottom-0 pointer" onClick={() => get_fiche_paie(0)}>
                                                <strong>{t('FICHE PAIE')}   </strong>     <i className="fa fa-file-pdf-o " />

                                            </th>
                                            <th className="border-bottom-0">

                                            </th>

                                        </thead>
                                        <tbody>
                                            {loading && <p></p>}
                                            {!loading && data_map.map((item, index) => (
                                                <tr key={index} style={{ backgroundColor: click?.index === index ? '#96b0ff' : null }}>
                                                    {regule_impo && <td> <input
                                                        type="checkbox"
                                                        onClick={() => select_regule(item)}
                                                        checked={list_regule.find(e => e.user_id === item.user_id)}
                                                    /></td>}
                                                    <td>{index + 1}</td>
                                                    <td onClick={() => onclickshow(index)}>{item.nom}{' '}{item.prenom}</td>

                                                    <td>{formatFloat(item.salaire_base_calculer)}</td>
                                                    <td>{formatFloat(item.commission)}</td>
                                                    <td>{formatFloat(item.total_brut) || 0}</td>
                                                    <td>{formatFloat(item.rs_cnss) || 0}</td>
                                                    <td>{formatFloat(item.salaire_imposable) || 0}</td>
                                                    <td>{formatFloat(item.calcul_irpp_mois) || 0}</td>
                                                    <td>{formatFloat(item.salaire_irpp)}</td>
                                                    <td>{formatFloat(item.unp)}</td>
                                                    <td>{formatFloat(item.salaire_net)}</td>
                                                    <td>{float_decimale(item.total_retenue, 3)}</td>
                                                    <td>{formatFloat(item.net_servi_avant_arrondi)}</td>

                                                    <td>{formatFloat(-1 * item.difference)}</td>
                                                    <td>{formatFloat(item.net_servi)}</td>
                                                    <td onClick={() => save_cloturer_ligne(index)}>{item?.cloturer !== 1 ? <p style={{ color: 'green' }}>{t('Cloturer')}</p> : <p style={{ color: 'red' }}>{t('Decloturer')}</p>}</td>

                                                    {item.cloturer === 1 ? (<td onClick={() => get_fiche_paie(index)}>  <i className="fe fe-eye" /> Voir</td>) : <td></td>}
                                                    {item?.cloturer !== 1 ? <td onClick={() => removeItem(index)} style={{ color: 'red' }}> <IoPersonRemoveSharp />

                                                    </td> : null}

                                                    {(!regule_impo && parseInt(item.tc_irpp) === 1) ? (
                                                        item?.regule_impo === 1 ? <td>calculé avec regule</td> : <td   ><button className=' btn btn-primary pointer' onClick={() => reguleimpo(item)}>Regule d'impo</button> </td>) : <td></td>}
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {!ajout ? (
                                <div className='row'>

                                    {data_map.find(item => item?.cloturer === undefined || item?.cloturer === 0) &&
                                        <>
                                            <button className="btn btn-primary mr-2" onClick={() => save(data_map)}>
                                                {t("Enregistrer")}
                                            </button>
                                            <button className="btn btn-primary mr-2" onClick={() => save_cloturer(data_map)}>
                                                {t("Enregistrer et cloturer tout")}
                                            </button><button className="btn btn-primary mr-2" onClick={() => setajout(true)}>
                                                {t("+")}
                                            </button>
                                        </>

                                    }




                                </div>

                            ) :
                                <div>
                                    <List_user data={data} ajout={ajout} t={t} setajout={setajout} setuser_selected={setuser_selected} user_selected={user_selected} calcul_paie={calcul_paie} />
                                </div>
                            }


                        </div>

                    </div>
                </div>
                {click?.clicked &&
                    <Info_paie_employe
                        t={t}
                        list_rubriques={list_rubriques}
                        list_avantages={list_avantages}
                        setlist_avantages={setlist_avantages}
                        setlist_rubriques={setlist_rubriques}
                        data_map={data_map}
                        click={click}
                        setclick={setclick}
                        setshow={setshow}
                        data_change={data_change}
                        setdata_map={setdata_map}
                        setdata_change={setdata_change}
                        CalculSalaire={CalculSalaire}
                        calculbrut={calculbrut}
                        param_paie_active={param_paie_active}
                        change={change}
                        setchange={setchange}
                        save={save}
                        prime={prime}
                    />
                }

            </div >
            <div hidden={!fiche}>

                <>
                    <div className="col-12 center">
                        <div className='row'>
                            <button className="btn btn-primary" onClick={downloadPDF}>
                                <i className="fa fa-file-pdf-o" />
                            </button>
                            <button className="btn btn-primary" onClick={downloadallPDF}>
                                {t('Télécharger Tout')}
                            </button>
                        </div>

                        <div className="tabs-menu1">
                            {/* Tabs */}
                            <ul className="nav panel-tabs">
                                <li>
                                    <a href="#tab5" className="active" data-toggle="tab">
                                        {t("Modélé 1")}
                                    </a>
                                </li>
                                <li>
                                    <a href="#tab6" data-toggle="tab">
                                        {t("Modéle 2")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                            <div className="tab-content">
                                <div className="tab-pane active A4" id="tab5">
                                    <FichepaieModel2 t={t} employeeData={data_employee} societe={societe} prime={prime} />
                                </div>
                                <div className="tab-pane A4" id="tab6">
                                    <Fichepaie t={t} employeeData={data_employee} societe={societe} prime={prime} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={() => setshow(false)} size="lg"    >
                        <Modal.Header >
                            <Modal.Title>{data_map[click?.index]?.nom + ' ' + data_map[click?.index]?.prenom} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CardPerso userId={data_map[click?.index]?.user_id} />
                        </Modal.Body>

                    </Modal>

                </>
            </div>
        </>


    )
}
/*   {click.titre === 'Indemnités' ? (
                                        <>

                                            {data_map[click.index].rubs && data_map[click.index].length !== 0 &&
                                                data_map[click.index].rubs.map((item, index) => (
                                                    <div key={index} className='row'>
                                                        <p className='col-2'>{t(item.libelle)}</p>
                                                        <p className='col-2'></p>
                                                        <p className='col-2'></p>
                                                        <p className='col-2'>{t(item.valeur)}</p>
                                                    </div>
                                                ))
                                            }







                                        </>
                                    ) : click.titre === 'Retenue CNSS' ? (
                                        <><div className='row'>
                                            <p className='col-2'>{t(" Taux CNSS")}</p>
                                            <p className='col-2'></p>
                                            <p className='col-2'></p>
                                            <p className='col-2'>{data_map[click.index].param_paie_active.val_cnss}%</p>

                                        </div>
                                            <div className='row'>
                                                <p className='col-2'>{t(" Brut soumis a CNSS")}</p>
                                                <p className='col-2'></p>
                                                <p className='col-2'></p>
                                                <p className='col-2'>{data_map[click.index].brut_cnss}</p>

                                            </div>
                                        </>
                                    ) : null

                                    }*/
/*   irpp.forEach(element => {
                            const max = parseFloat(element.max)
                            const min = parseFloat(element.min)

                            if (copie_net > 0) {

                                if (element.max !== null) {
                                    if (copie_net > max) {

                                        const s = max - min;
                                        calcul_irpp_css = parseFloat((calcul_irpp_css + (s * (element.taux + (param_paie_active.taux_css / 100)))).toFixed(3))
                                        copie_net = copie_net - s;




                                    } else {
                                        const s = copie_net;
                                        calcul_irpp_css = parseFloat((calcul_irpp_css + (s * (element.taux + (param_paie_active.taux_css / 100)))).toFixed(3))
                                        copie_net = copie_net - s;

                                    }
                                }
                                else {

                                    calcul_irpp_css = parseFloat((calcul_irpp_css + (copie_net * (element.taux + (param_paie_active.taux_css / 100)))).toFixed(3))
                                    copie_net = copie_net - copie_net;


                                }
                            }


                        });*/
/*    <div className='row'>
                        <strong className='col-8'>{t('Nombre de jour de travaille  ')}</strong>
                        <p className='col-2'>{data_map[click.index].nbrjtravaille}</p>
                    </div>*/
/*<div className='row'>
<strong className='col-4'>{t('salaire retenue')}</strong>
<p className='col-2'>{data_map[click.index].total_Ab}</p>
<p className='col-2' style={{ color: 'red' }}>{data_change.find(item => item.userid === data_map[click.index].user_id && item.name === 'Njr_Ab')?.oldvalue}</p>

<p className='col-2'>{data_map[click.index].salaire_journee}</p>

<p className='col-2'>{data_map[click.index].salaire_retenue}</p>
</div>*/