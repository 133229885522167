import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import dayListPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import styled from "styled-components";
import { acceptConge, getAllCongeByCode, getAllCongeByDate, getAllValideCongeByCode } from "../../Services/Pointeuse/CongeApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { getHolidayByCode } from "../../Services/Pointeuse/HolidayApi";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { filterCongeTitleByTitle } from "../../Utils/SortObject";
import ImageComponent from "../Styles/ImageComponent";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import CongeCardHeader from "./CongeCardHeader";
import { t } from "i18next";
import swal from "sweetalert";

const Event = styled.div`
  display: inline-flex;
  font-weight: bold;
  vertical-align: top;
`;
const ImageEvent = styled.div`
  flex: 1;
  height: 100%;
`;

const TextEvent = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  margin-top: 4px;
`;

function CongeCalendar() {
  const calendarComponentRef = useRef();
  const modalRef = useRef();
  const closeModal = useRef();
  const history = useHistory();
  const [initialEvent, setinitialEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [events, setevents] = useState([]);

  const [user, setuser] = useState({
    id: '',
    type: '',
    user_id: '',
    nom: "",
    img: "",
    motif: "",
    nbr_jour: null,
  }
  );

  /**
   * Add a number of days to a date
   * @param date - The date to add days to.
   * @param days - The number of days to add to the date.
   * @returns The date that is 7 days after the date that is passed in.
   */
  function addDayToDate(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * It gets all the holidays and all the valid conges and merge them into one array.
   */


  const handleDatesSet = async (e) => {

    const datedebut = e.startStr.split("T")[0];
    const dateFin = e.endStr.split("T")[0];
    /*  const annee = datedebut.split('-')[0]
      getHolidayByCode().then((res) => {
        console.log("Holidaytres",res)
  
        const newEvents =res.data.map(({ libelle,  date, nbre_jours, type }) => ({
          title: `${libelle } `,
          start: `${annee}-${date}`,
          end: addDayToDate(`${annee}-${date}`, nbre_jours),
          color: getStatusColor("Holiday", type),
          allDay: true,
        }));
        
        console.log('holiday',newEvents)
      setevents([...events,newEvents])
        })*/

    const res = await getAllCongeByDate(datedebut, dateFin);
    const newEvents = res.data.map((d) => {
      const startDate = new Date(d.date.split(" ")[0]); 
      const isFriday = startDate.getDay() === 5; 
      const adjustedNbrJour = isFriday ? d.nbr_jour + 2 : d.nbr_jour;

      return {
        title: `${d.nom} ${d.prenom}`,
        start: d.date.split(" ")[0],
        end: addDayToDate(d.date.split(" ")[0], adjustedNbrJour), // Utilisation de adjustedNbrJour ici
        color: getStatusColor(d.type, d.valide),
        allDay: true,
        id: d.id,
        valide: d.valide,
        type: d.type,
        nbrejrs: adjustedNbrJour, // Utilisation de adjustedNbrJour, pas d.nbr_
      }
    })

    setevents(newEvents)
    // setEvents(newEvents);
  };
  const handleDateClick = (args) => {
    // bind with an arrow function
    // alert(arg.dateStr);
    // let calendarApi = calendarComponentRef.current.getApi();
    // console.log(calendarApi);
  };

  const handleEventClick = ({ event, el }) => {

    const findEvent = events.find(item => parseInt(item.id) === parseInt(event._def.publicId))
    if (findEvent) {

      setuser({
        id: findEvent.id,
        type: "conge",
        nom: findEvent.title,
        user_id: findEvent.user_id,
        img: findEvent.image,
        motif: EtatConge(findEvent.type, findEvent.valide),
        nbr_jour: findEvent.nbrejrs,
      });
      modalRef.current.click();
    }

  };
  const EtatConge = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return "Demande conge";
    } else if (type === "conge" && valide === 0) {
      return "Conge invalide";
    } else if (type === "conge" && valide === 1) {
      return "conge valide";
    }
  }
  const navigateUser = (id) => {
    closeModal.current.click();
    history.push("/admin/users/" + id);
  };

  /**
   * It filters the events by the title.
   * @param e - The event object that contains the data about the event that was triggered.
   */
  const handleSearch = (e) => {
    const dataSearch = filterCongeTitleByTitle(initialEvent, e.target.value);
    setevents(dataSearch);
  };


  const getStatusColor = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return "blue";
    } else if (type === "conge" && valide === 0) {
      return "red";
    } else if (type === "conge" && valide === 1) {
      return "green";
    } else if (type === "Holiday" && valide === "CNP") {
      return "#f5bb16";

    } else if (type === "Holiday" && valide === "CP") {
      return "#ff5b13";

    }
  };

  useEffect(() => {
    if (calendarComponentRef.current) {
      const calendarApi = calendarComponentRef.current.getApi();
      calendarApi.updateSize();
    }
  }, [events]);

  const handleReject = (id, nbjour, receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //id,affect_solde,userid, nbr_jour, valide
        acceptConge(id, nbjour, "0").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          window.location.reload()
        });
      }
    });
  };
  const handleAccept = (nbjour, id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptConge(id, nbjour, "1").then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          }); window.location.reload()

        });
      }
    });
  };

  function renderEventContent(eventInfo) {
    return (
      <Event>
        <ImageEvent>
          <img className="avatar" src={eventInfo.event._def.extendedProps.image} />
        </ImageEvent>
        <TextEvent>
          <h5>{eventInfo.event.title}</h5>

        </TextEvent>
      </Event>
    );
  }

  return (
    <>
      <CongeCardHeader />

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Calendrier congés")}</h4>
            </div>

            <div className="card-body">
              {/*<div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-primary-light mr-2">
                    <i className="feather feather-check-circle text-primary" /> {t("congé")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("Jour férié")}
                  </span>
                </div>
              </div>*/}

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          {/* <label>
          Afficher{" "}
          <select
            value={size}
            className="form-control"
            id="exampleFormControlSelect1"
            onChange={handleChangeSize}
          >
            <option value={8}>8</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={100}>100</option>
          </select>{" "}
          entrées
        </label> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          {/* <Link to="#" className="action-btns" data-tip="télécharger">
                            <i className="feather feather-download  text-secondary" />
                          </Link>

                          <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <FullCalendar

                          defaultView="monthGridMonth"
                          header={{
                            left: t("prev,next today"),
                            center: t("title"),
                            right: t("dayGridMonth,timeGridWeek,timeGridDay,listWeek"),
                          }}
                          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, dayListPlugin]}
                          weekends={false}
                          dateClick={handleDateClick}
                          eventClick={handleEventClick}
                          events={events}
                          ref={calendarComponentRef}
                          firstDay={1}
                          selectable={true}
                          themeSystem="bootstrap"
                          displayEventTime={true}
                          locale="fr"
                          aspectRatio={1.9}
                          longPressDelay={1}
                          editable={false}
                          eventContent={renderEventContent}
                          datesSet={handleDatesSet}

                        />

                        <button hidden type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" ref={modalRef}>
                          {t("Launch demo modal")}
                        </button>

                        {user.type === "conge" && (
                          <div
                            className="modal fade"
                            id="exampleModalCenter"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="exampleModalLongTitle">
                                    {user.motif}
                                  </h5>
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeModal}>
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-8">
                                      <h2 className="text-primary" onClick={() => navigateUser(user.user_code_id)}>
                                        <b>
                                          <span className="glyphicon glyphicon-user" /> {user.title}
                                        </b>
                                      </h2>
                                      <p>
                                        <strong>
                                          <span className="glyphicon glyphicon-ok-circle" />
                                          {t("Nombre de jour")} :
                                        </strong>
                                        {user.nbr_jour}
                                      </p>

                                    </div>
                                    <div className="col-xs-12 col-sm-4 text-center">
                                      <ImageComponent
                                        atr={"center-block img-circle img-responsive profile-img"}
                                        picture={user.imguser}
                                      ></ImageComponent>
                                    </div>
                                  </div>
                                  {user.motif === "Demande conge" && <div className="row">
                                    <div className="col-6 ">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          handleAccept(

                                            user.nbr_jour,

                                            user.id,

                                          )
                                        }
                                        className="btn btn-lg btn-outline-light w-55 text-success"
                                      >
                                        {t("Accepter")}
                                      </Link>
                                    </div>
                                    <div className="col-6">
                                      <Link
                                        to="#"
                                        onClick={() => handleReject(user.id, user.nbr_jour, user.user_id)}
                                        className="btn btn-lg btn-outline-light w-55 text-danger"
                                      >
                                        {t("Refuser")}
                                      </Link>
                                    </div>

                                  </div>}

                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {user.type === "holiday" && (
                          <div
                            className="modal fade"
                            id="exampleModalCenter"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="exampleModalLongTitle">
                                    {user.title}
                                  </h5>
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                      <ImageComponent atr={"center-block img-circle img-responsive profile-img"} picture={null}></ImageComponent>
                                      {/* <img
                                        src={"dist/img/conge/party.png"}
                                        alt="image user"
                                        className="center-block img-circle img-responsive profile-img"
                                      /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CongeCalendar;
