import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { date_fr } from '../../Utils/DateUtils';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Times-Roman',
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 14,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  text: {
    marginBottom: 5,
  },
  textheader:{
    marginBottom: 15,
    fontSize:10
  },
  signature: {
    marginTop: 30,
    textAlign: 'right',
  },
});
const today = new Date();
const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
const formattedDate = today.toLocaleDateString('fr-CA', options).replace(/-/g, '/'); // Format DD/MM/YYYY


// Create Document Component
const QuittancePDF = ({list,user,types_contrat,societe,perso}) => (
    
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <Text style={styles.header}>QUITTANCE DE SOLDE DE TOUT COMPTE 
      RELATIVE A UN CONTRAT DE TRAVAIL CDI

      </Text>
     

      {/* Content */}
      <View style={styles.section}> 
        <Text style={styles.textheader}>
          Conformément aux articles 6 alinéa 2 paragraphe 2, alinéa 4, paragraphe 2, 14, 14 bis et 14 ter du code de travail tel que modifié par la loi n°96-62 du 15 juillet 1996, les textes législatifs ultérieurs et la convention collective cadre qui régit l'activité de l'employeur,
        </Text>
        <Text style={styles.text}>
          Je soussigné(e) {user?.nom+' '+user?.prenom}, Matricule N° {perso.cnss} né le {date_fr(user?.birth_date)} à, titulaire de la carte d'identité nationale n° {perso.cin} délivrée à {perso.delivre_a}, recruté par la Société {societe?.nom} sise à Immeuble {societe?.adresse} par un contrat CDI, reconnais avoir reçu de la dite société {societe.nom} à la date du {date_fr(perso?.date_fincontrat)}, un montant de la somme de {list.reduce((ac, val) => ac + parseFloat(val.net_a_payer), 0)}DT ({list.map((item,index)=> (
            <>{item.status ==='mois'?(<>salaire du mois  {item.mois} : {item.net_a_payer||0} </>):item.status ==='conge'?(<>solde de congé : {item.net_a_payer||0} </>):<>{item.prime} : {item.net_a_payer||0} </>}{index <list.length-1 && <>+</>}</>))}), déduction faite de retenues légales en paiement de tout salaire, accessoires de salaire, de toutes primes et toutes indemnités légales et conventionnelles quelque soit leurs natures ou objets qui pourraient être dues au titre de l’exécution et la cessation de mon contrat de travail {types_contrat?.find(item => item.code ===perso.type_contrat)?.libelle} convenu d’un commun accord avec mon employeur la Société {societe.nom} à la suite de mon départ daté au {date_fr(perso?.date_embauche)}.
        </Text>
        <Text style={styles.text}>
          En acceptant la dite somme, je déclare expressément n’avoir plus rien à prétendre de mon employeur la Société {societe.nom} pour aucune cause que ce soit, et quelque soit sa date, sa volontaire forfaitaire, transaction, transactionnel, global et définitif sans aucune réserve pour solde de tous comptes droits, indemnités et prétentions en vertu des articles 6 alinéa 2 paragraphe 2, 14 et 14 bis du code du travail tel que modifié par la loi n°96-62 du 15 juillet 1996, les textes législatifs ultérieurs et la convention collective cadre qui régit l’activité de l’employeur.
        </Text>
        <Text style={styles.text}>
          Par ailleurs, je déclare, et certifie qu’il n’existe aucun différent entre moi et mon employeur la Société {societe.nom} concernant toute la relation et période de travail que j’ai passée à son service.
        </Text>
        <Text style={styles.text}>
          Je reconnais également, que mon employeur la Société {societe.nom} a procédé au paiement de tous mes droits (sans exception ni réserves) quelque soient leurs natures ou objets. Toutes prétentions futures ayant pour cause d’une manière directe ou indirecte mon contrat de travail et plus généralement toute la période de travail que j’ai passée au sein de la Société {societe.nom} seraient sans objet et non avenue, et entraîneraient par voies de conséquences ma responsabilité.
        </Text>
        <Text style={styles.text}>
          Enfin, par ma signature en bas des présentes je décharge et libère expressément mon employeur la Société {societe.nom} de toute responsabilités, dettes, obligations ou droits et quitus lui est donné.
        </Text>
      </View>

      {/* Footer */}
      <Text style={styles.text}>Fait à Tunis le {formattedDate}</Text>
      <Text style={styles.text}>Pour solde de tous comptes sans aucune réserve.</Text>
      <View style={styles.signature}>
        <Text>{user?.nom + ' '+user?.prenom}</Text>
        <Text>Matricule N° {perso.cnss}</Text>
        <Text>
          Titulaire de la Carte d’Identité Nationale N° {perso.cin} délivrée à {perso.delivre_a}
        </Text>
      </View>
    </Page>
  </Document>
);

export default QuittancePDF;
