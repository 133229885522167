import React, { useEffect, useLayoutEffect, useState } from 'react'
import { getBaremeIrppByCode } from '../../../Services/Pointeuse/BaremeIrppApi';
import { getEntrepriseByCode } from '../../../Services/Pointeuse/EntrepriseApi';
import { addReportImpot, getpaie_yearallUsers } from '../../../Services/paie/paie';
import { getparam_paie, getparam_paie_active, getuser_rub_codesociete } from '../../../Services/paie/parametrage_paie';
import _ from 'lodash';
import { t } from "i18next";

import { getUsersPersonnelByCode } from '../../../Services/Pointeuse/UsersApi';
import { calcul_irpp } from '../CalculIrpp';
import { toast } from 'react-toastify';
import { removeAttributeFromObjects } from '../../../Utils/ActivityUtils';
import { formatFloat } from '../../../Utils/helper';
import { Link, useHistory } from "react-router-dom";
export default function ReportImpot(props) {
    const [irpp, setIrpp] = useState([])
    const [societe, setSociete] = useState(null);
    const [param_paie_active, setparam_paie_active] = useState()
    const [user_selected, setuser_selected] = useState(props?.location.state?.user_selected || [])
    const [exercice_selectionne, setexercice_selectionne] = useState();
    const [exercice_actuel, setexercice_actuel] = useState()
    const [listrub, setlistrub] = useState([]);
    const [listAvfiscaux, setlistAvfiscaux] = useState([]);
    const [loading, setloading] = useState(false)
    const [dataReport, setDataReport] = useState([])
    const [data, setdata] = useState([])
    const [dateP, setdateP] = useState()
    const history = useHistory();

    useLayoutEffect(() => {
        getBaremeIrppByCode(exercice_selectionne?.id).then((res) => {

            setIrpp(res?.data)

        })
    }, [exercice_selectionne])
    useLayoutEffect(() => {

        getEntrepriseByCode().then((res) => {
            const data = res?.data;
            if (data && data.matricule_fiscal) {
                data.matricule_fiscal = data?.matricule_fiscal?.replace(/\//g, '');

                setSociete(data);
            }

        });
        getUsersPersonnelByCode().then((res) => {
            setdata(res?.data)
        })
        getuser_rub_codesociete().then((res) => {
            console.log('listrub', res.data)
            const list_rub = res.data.ru.map(item => {
                const copie = { ...item }
                copie.date_debut = copie.date_debut.split('T')[0]
                copie.date_fin = copie.date_fin.split('T')[0]
                return copie

            });
            console.log('list_rub', list_rub)
            setlistrub(list_rub)
            const list_avantage = res.data.Av.map(item => {
                const copie = { ...item }
                copie.date_debut = copie.date_debut.split('T')[0]
                copie.date_fin = copie.date_fin.split('T')[0]
                return copie

            });;
            setlistAvfiscaux(list_avantage)

        })
    }, []);

    useLayoutEffect(() => {
        if (props?.location.state?.exercice) {
            setexercice_selectionne(props?.location.state?.exercice)
            getparam_paie(props?.location.state?.exercice?.id).then((res) => {
                if (res.status === 200) {
                    setparam_paie_active(res.data.data[0])
                }
            })
        } else {
            getparam_paie_active().then((res) => {
                if (res.status === 200) {
                    setparam_paie_active(res.data.data[0])
                }
            })
        }
        if (props?.location.state?.exercice_selectionne) {
            setexercice_actuel(props?.location.state?.exercice_selectionne)

        }

        if (props?.location.state?.user_selected) {
            setuser_selected(props?.location.state?.user_selected)

        }
        if (props?.location.state?.date) {
            setdateP(props?.location.state?.date)

        }


    }, [props])
    useLayoutEffect(() => {
        if (param_paie_active) {
            getpaie_yearallUsers(exercice_selectionne?.code_exercice).then((res) => {
                if (res.status === 200) {
                    const listpaie = res.data.filter(objet => user_selected.includes(parseInt(objet.id_users)))
                    const groupedlist = _.groupBy(listpaie, 'id_users');

                    const result = _.map(groupedlist, (group, id_user) => {

                        const total_imposable = Array.isArray(group)
                            ? group.reduce((acc, curr) => acc + (parseFloat(curr.salaire_imposable) || 0), 0)
                            : 0;
                        const total_irpp = Array.isArray(group) ? group?.reduce((acc, curr) => acc + (parseFloat(curr.calcul_irpp_mois) || 0), 0) : 0;
                        const total_irpp_css = Array.isArray(group) ? group?.reduce((acc, curr) => acc + (parseFloat(curr.calcul_irpp_css_mois) || 0), 0) : 0;
                        const total_css = total_irpp_css - total_irpp;
                        // const findPersonnel 

                        const personnel = data.find(item => item.user_id === parseInt(id_user))

                        if (personnel && personnel.tc_irpp === 1) {
                            console.log('personnel', personnel)
                            //find rubrique avantage 
                            const list_total_rub_non_irpp = listrub?.filter(item => parseInt(item.user_code) === parseInt(id_user))

                            const total_rub_non_irpp = list_total_rub_non_irpp?.reduce((acc, curr) => {
                                if (curr.irpp === 0) {
                                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                        return acc + (curr.valeur_annuel / personnel.nb_s);
                                    } else {
                                        return acc + curr.valeur_mensuel;
                                    }

                                }
                                else {
                                    return acc + 0;

                                }
                            }, 0);



                            // find avantage fiscaux
                            const list_total_avantage_fiscaux = listAvfiscaux?.filter(item => parseInt(item.user_code) === parseInt(id_user))

                            const total_avantage_fiscaux = list_total_avantage_fiscaux?.reduce((acc, curr) => {

                                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                                    return acc + curr.valeur_annuel;
                                } else {
                                    return acc + (curr.valeur_mensuel * personnel.nb_s);
                                }
                            }, 0);

                            /// avantage enfant

                            let totalenfant = 0
                            if (personnel.enfant >= 1 && param_paie_active.enfant_1er === 1) {

                                totalenfant += param_paie_active.val_enfant_1er

                            }
                            if (personnel.enfant >= 2 && param_paie_active.enfant_2eme === 1) {
                                totalenfant += param_paie_active.val_enfant_2eme

                            }
                            if (personnel.enfant >= 3 && param_paie_active.enfant_3eme === 1) {
                                totalenfant += param_paie_active.val_enfant_3eme

                            }
                            if (personnel.enfant >= 4 && param_paie_active.enfant_4eme === 1) {
                                totalenfant += param_paie_active.val_enfant_4eme

                            }

                            // enfant handicapé
                            let totalenfant_h = 0;
                            if (personnel.enfant_handicape >= 1 && param_paie_active.Enfant_handicape === 1) {
                                totalenfant_h = personnel.enfant_handicape * param_paie_active.val_Enfant_handicape
                            }
                            // enfant etudiant 
                            let totalenfantN_b = 0
                            if (personnel.enfant_N_B >= 1 && param_paie_active.etudiant_N_B === 1) {
                                totalenfantN_b = personnel.enfant_N_B * param_paie_active.val_etudiant_N_B
                            }
                            // parent a charge 
                            let totalparent = 0

                            if (personnel.Parent_charge === 1 && param_paie_active.Parent_charge === 1) {
                                totalparent = param_paie_active.val_Parent_charge
                            }
                            let tauxchef_famille = 0
                            if (personnel.chef_famille === 1 && param_paie_active.chef_famille === 1) {
                                tauxchef_famille = param_paie_active.val_chef_famille

                            }

                            // dix %

                            let new_impo_moindixp = total_imposable;
                            if (param_paie_active.dix_p === 1) {
                                const moindixp = total_imposable * (param_paie_active.val_dix_p / 100);
                                new_impo_moindixp = moindixp > param_paie_active.montant_dix_p ? total_imposable - param_paie_active.montant_dix_p : total_imposable - moindixp;
                            }
                            const net = new_impo_moindixp - total_rub_non_irpp - totalenfant - totalenfant_h - totalenfantN_b - totalparent - tauxchef_famille - total_avantage_fiscaux;

                            const res_irpp = calcul_irpp(param_paie_active, irpp, net, personnel.nb_s);
                            const new_calcul_irpp_css = res_irpp.calcul_irpp_css_annuel;
                            const new_calcul_irpp = res_irpp.calcul_irpp_annuel;
                            const new_calcul_css = new_calcul_irpp_css - new_calcul_irpp;

                            // impot 
                            const irpp_reste = new_calcul_irpp - total_irpp;
                            const css_reste = new_calcul_css - total_css;
                            const irpp_css_reste = css_reste + irpp_reste

                            return {
                                code_generated	:localStorage.getItem('code_generated'),
                                nom: personnel.nom + ' ' + personnel.prenom,
                                id_user: id_user,
                                exercice: exercice_selectionne?.id,
                                irpp: irpp_reste,
                                css: css_reste,
                                irpp_css: irpp_css_reste
                            }










                        }

                    });
                    const filteredList = result.filter(item => item !== undefined && item?.irpp > 0 && item?.css > 0);
                    setDataReport(filteredList)








                }

            })
        }

    }, [listAvfiscaux, listrub, data])
    const savereport = () => {
        const updateddataReport = removeAttributeFromObjects(dataReport, "nom");

        addReportImpot(updateddataReport).then((res) => {
            if (res.status === 201) {
                toast.success(("report enregistré"))
                history.push({
                    pathname: '/admin/MatricePaie/',
                    state: {
                        user_selected: user_selected,
                        date: dateP,
                        exercice: exercice_actuel,
                        prime: null,
                        exercice_precedent:exercice_selectionne
                    },
                });

            } else {
                toast.error((res?.message))
            }

        })
    }



    return (
        <div className="card">
            <div className='card-header'>
                <h1>{t('Report impot exercice')} {exercice_selectionne?.code_exercice}</h1>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="table-responsive hr-attlist">

                        <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                            <thead>

                                <th className="border-bottom-0">
                                    <strong>{t('NOM PRENOM')}</strong>
                                </th>
                                <th className="border-bottom-0">
                                    <strong>{t('Irpp')}</strong>
                                </th>
                                <th className="border-bottom-0">
                                    <strong>{t('Css')}</strong>
                                </th>

                            </thead>
                            <tbody>
                                {loading && <p></p>}
                                {!loading && dataReport && dataReport.map((item, index) => (
                                    <tr>
                                        <td>{item?.nom}</td>
                                        <td>{formatFloat(item.irpp)}</td>
                                        <td>{formatFloat(item.css)}</td>
                                        <td>{formatFloat(item.irpp_css)}</td>

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                    <button className=' btn btn-primary pointer' onClick={() => savereport()}>{t('Enregistrer et calcul de paie')}</button>
                    <Link
                        to={{
                            pathname: '/admin/MatricePaie/',
                            state: { user_selected: user_selected, date: dateP, exercice: exercice_actuel, prime: null }
                        }}

                        className="btn btn-primary float-right ml-2"
                    >
                        {t('Calcul de paie')}
                    </Link>
                </div>
            </div>
        </div>
    )
}
