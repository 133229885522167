import React, { useEffect, useState, useCallback, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import styled from "styled-components";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";

import {
  acceptTeletravail,
  addTeletravail,
  deleteTeletravailById,
  getTeletravaiByCodeAndDate,
  refuseTeletravail,
} from "../../Services/Pointeuse/TeletravailApi";
import { t } from "i18next";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ImageComponent from "../Styles/ImageComponent";

const Event = styled.div`
  display: inline-flex;
  font-weight: bold;
  vertical-align: top;
`;

const ImageEvent = styled.div`
  flex: 1;
  height: 100%;
`;

const TextEvent = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  margin-top: 4px;
`;

const TeleTravailCalendarAdmin = () => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef();
  const closeModal = useRef();

  const [user, setuser] = useState({
    id: '',
    type: '',
    user_id: '',
    nom: "",
    img: "",
    motif: "",
    nbr_jour: null,
  }
  );

  const handleDateClick = async (e) => {

  };

  const handleDatesSet = async (e) => {
    const datedebut = e.startStr.split("T")[0];
    const dateFin = e.endStr.split("T")[0];
    const res = await getTeletravaiByCodeAndDate(datedebut, dateFin);
    const newEvents = res.data.map((d) => ({
      titre:getStatus(d.type, d.valide),
      title: `${d.nom} ${d.prenom}`,
      start: d.date_debut,
      end: d.date_fin,
      color: getStatusColor(d.type, d.valide),
      allDay: true,
      id: d.id,
      valide: d.valide,
      type: d.type,
      fonction: d.fonction,
      user_id: d.user_id,
      image: "assets/images/users/avatar.png",
    }));
    setEvents(newEvents);
  };

  const getStatusColor = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return "blue";
    } else if (type === "teletravail" && valide === 0) {
      return "red";
    } else if (type === "teletravail" && valide === 1) {
      return "green";
    }
  };

  const getStatus = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return "Demande teletravail";
    } else if (type === "teletravail" && valide === 0) {
      return "Demande Invalide";
    } else if (type === "teletravail" && valide === 1) {
      return "Demande valide";
    }
  };

  const getStatusIcon = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return (
        <span>
          <i className="fa fa-star" style={{ color: "white" }} />{" "}
          {t("en cours")}
        </span>
      );
    } else if (type === "teletravail" && valide === 0) {
      return (
        <span style={{ color: "white" }}>
          <i
            className="feather feather-x-circle"
            style={{ color: "white" }}
          />{" "}
          {t("refusé")}
        </span>
      );
    } else if (type === "teletravail" && valide === 1) {
      return (
        <span>
          <i className="feather feather-check-circle" style={{ color: "white" }} />
          {t("accepté")}
        </span>
      );
    }
  };

  const renderEventContent = (eventInfo) => {
    const findEvent = events.find(
      (item) => parseInt(item.id) === parseInt(eventInfo.event._def.publicId)
    );

    return (
      <Event>
        <ImageEvent>
          <img
            className="avatar"
            src={eventInfo.event._def.extendedProps.image}
            alt={eventInfo.event.title}
          />
        </ImageEvent>
        <TextEvent>
          <h5>{eventInfo.event.title}</h5>
          {getStatusIcon(findEvent?.type, findEvent?.valide)}
        </TextEvent>
      </Event>
    );
  };

  const handleEventClick = ({ event, el }) => {

    const findEvent = events.find(item => parseInt(item.id) === parseInt(event._def.publicId))
    console.log('findEvent',findEvent)
    if (findEvent) {

      setuser(findEvent);
      modalRef.current.click();
    }

  };
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.updateSize();
    }
  }, [events]);
  /**
   * Accepts a teletravail request
   * @param id - the id of the teletravail you want to accept
   */
  const handleAccept = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptTeletravail(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          }); window.location.reload()

        });
      }
    })

  };
  /**
   * It calls the API to refuse a teletravail request.
   * @param id - the id of the teletravail you want to refuse
   */
  const handleReject = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        refuseTeletravail(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          }); window.location.reload()

        });
      }
    })
  };
  return (
    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header border-0">
            <h4 className="card-title">{t("Calendrier Tele travail")}</h4>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          <div className="table-responsive">
            <div className="row" style={{ height: "1000px" }}>
              <div className="col-12">
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  weekends={false}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  events={events}
                  eventContent={renderEventContent}
                  datesSet={handleDatesSet}
                />
                <button hidden type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" ref={modalRef}>
                  {t("Launch demo modal")}
                </button>

                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLongTitle">
                            {user.titre}
                          </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeModal}>
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-xs-12 col-sm-8">
                              <h2 className="text-primary" >
                                <b>
                                  <span className="glyphicon glyphicon-user" /> {user.title}
                                </b>
                              </h2>
                              <p>
                                <strong>
                                  <span  />
                                  {t("Fonction")} :
                                </strong>
                                {user.fonction}
                              </p>
                              <p>
                                <strong>
                                  <span className="glyphicon glyphicon-ok-circle" />
                                  {t("Date Debut")} :
                                </strong>
                                {user.start}
                              </p>
                              <p>
                                <strong>
                                  <span className="glyphicon glyphicon-ok-circle" />
                                  {t("Date Fin")} :
                                </strong>
                                {user.end}
                              </p>
                              

                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">

                              <ImageComponent
                                atr={"center-block img-circle img-responsive profile-img"}
                                picture={user.image}
                              ></ImageComponent>
                            </div>
                          </div>

                          {user.type === "demande" && <div className="row">
                            <div className="col-6 ">
                              <Link
                                to="#"
                                onClick={() =>
                                  handleAccept(  user.id)
                                }
                                className="btn btn-lg btn-outline-light w-55 text-success"
                              >
                                {t("Accepter")}
                              </Link>
                            </div>
                            <div className="col-6">
                              <Link
                                to="#"
                                onClick={() => handleReject(user.id)}
                                className="btn btn-lg btn-outline-light w-55 text-danger"
                              >
                                {t("Refuser")}
                              </Link>
                            </div>

                          </div>}

                        </div>
                      </div>
                    </div>
                  </div>
                


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeleTravailCalendarAdmin;
