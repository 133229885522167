import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { editUserStatus, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterTeletravailByWord, filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import {
  acceptTeletravail,
  deleteTeletravailById,
  getAllTeletravailByCode,
  refuseTeletravail,
} from "../../Services/Pointeuse/TeletravailApi";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import { getfilter } from "../../Utils/ObjectFilter";
import PieChartTeletravail from "../Charts/CustumChartJs/PieChartTeletravail";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import moment from "moment";
import { error_message } from "../alerte/AlerteMessage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import TeleTravailCalendar from "./TeleTravailCalendar";
import TeleTravailCalendarAdmin from "./TeleTravailCalendarAdmin";
function ListTeletravail() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [valides, setvalides] = useState(null);
  const [nonValides, setnonValides] = useState(null);
  const [demandes, setdemandes] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [calendar,setcalendar]=useState(false)
  /**
   * It gets all the data from the API and sets the data in the state.
   */
  const getData = () => {
    setLoading(true);
    getAllTeletravailByCode()
      .then((res) => {
        setdata(res.data.reverse());
        setallInitialData(res.data.reverse());
        setdemandes(getfilter(res.data.reverse(), "type", t("demande")));
        const allTeletravail = getfilter(res.data.reverse(), "type", t("teletravail"));
        setnonValides(getfilter(allTeletravail, "valide", "0"));
        setvalides(getfilter(res.data.reverse(), "valide", "1"));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * Accepts a teletravail request
   * @param id - the id of the teletravail you want to accept
   */
  const handleAccepte = (id) => {
    acceptTeletravail(id).then(() => {
      getData();
    });
  };
  /**
   * It calls the API to refuse a teletravail request.
   * @param id - the id of the teletravail you want to refuse
   */
  const handleRefuse = (id) => {
    refuseTeletravail(id).then(() => {
      getData();
    });
  };

  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTeletravailById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      const dataSearch = filterTeletravailByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const onChangeDate = () => {
 
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date);
      if(startDate > endDate){
        error_message(t("Attention La date début doit être inférieur à la date fin !!"))
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    setcurrentData(filteredDate);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Télétravail")}</h4>
        </div>
      <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              
              <ButtonHeaderComponent />
              <button className="btn btn-primary" onClick={()=>setcalendar(!calendar)}>{calendar ?" Liste":" Calendrier"}</button>
            </div>
          </div>
        </div> 
      </div>

     

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0 d-flex justify-content-center">
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">{data.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{valides?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total validés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{nonValides?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total non validés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">{demandes?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total demandes")}</h5>
                </div>
                {/* <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">2</span>
                  <h5 className="mb-0 mt-3">Late Days</h5>
                </div> */}
                {/* <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-pink-transparent">5</span>
                  <h5 className="mb-0 mt-3">Holidays</h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      
     
       <div className="row">

       <div className="col-xl-9 col-md-12 col-lg-12">
         <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
           <div className="card-header  border-0">
             <h4 className="card-title">{t("Liste Télétravail")}</h4>
        
             <div className="card-options">
               <Link
                 to="#"
                 className="card-options-fullscreen mr-2"
                 data-toggle="card-fullscreen"
                 onClick={handleCollapse}
               >
                 <i className="fe fe-maximize" />
               </Link>
             </div>
           </div>
                  {calendar?(
                    <div className="card-body">
                    <TeleTravailCalendarAdmin/>
       
                  
                  </div>
                  ):<div className="card-body">
             <div className="d-flex mb-6 mt-5">
               <div className="mr-3">
                 <label className="form-label">{t("Note")}:</label>
               </div>
               <div>
                 <span className="badge badge-success-light mr-2">
                   <i className="feather feather-check-circle text-success" /> {t("accepté")}
                 </span>
                 <span className="badge badge-danger-light mr-2">
                   <i className="feather feather-x-circle text-danger" /> {t("refusé")}
                 </span>
                 <span className="badge badge-warning-light mr-2">
                   <i className="fa fa-star text-warning" /> {t("en cours")}
                 </span>
               </div>
             </div>

             {loading && <CustomSyncLoader></CustomSyncLoader>}
             {!loading && error && <ErrorCustum></ErrorCustum>}
             {!loading && !error && data && (
               <div className="table-responsive">
                 <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                   <div className="row">
                     <div className="col-sm-12 col-md-6">
                       <div className="dataTables_length" id="hr-table_length">
                         <label>
                           {t("Afficher")}{" "}
                           <select
                             value={size}
                             className="form-control"
                             id="exampleFormControlSelect1"
                             onChange={handleChangeSize}
                           >
                             <option value={8}>8</option>
                             <option value={20}>20</option>
                             <option value={40}>40</option>
                             <option value={60}>60</option>
                             <option value={100}>100</option>
                           </select>{" "}
                           {t("entrées")}
                         </label>
                       </div>
                     </div>
                     <div className="col-sm-12 col-md-6">
                       <div id="hr-table_filter" className="dataTables_filter">
                         <label className="mr-2">
                           <input
                             type="search"
                             className="form-control"
                             placeholder={t("Recherche...")}
                             aria-controls="hr-table"
                             onChange={handleSearch}
                           />
                         </label>

                         <ExportCsv data={data} name={"List_demandes_télétravail"} />
                         <ExportPdf
                           data={data}
                           name={"List_demandes_télétravail"}
                           columns={["nom", "prenom", "date_debut", "date_fin", "type"]}
                         />
                         <CopieContent />

                         {/* <Link to="#" className="action-btns" data-tip="imprimer">
                           <i className="feather feather-printer text-success" />
                         </Link>
                         <Link to="#" className="action-btns" data-tip="partager">
                           <i className="feather feather-share-2 text-warning" />
                         </Link> */}
                       </div>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-sm-12">
                       <table
                         className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                         id="hr-table"
                         role="grid"
                         aria-describedby="hr-table_info"
                       >
                         <thead>
                           <tr>
                             <th className="border-bottom-0 w-5"></th>
                             <th className="border-bottom-0">{t("Motif")}</th>
                             <th className="border-bottom-0">{t("Date début")}</th>
                             <th className="border-bottom-0">{t("Date fin")}</th>
                             <th className="border-bottom-0">{t("Type")}</th>
                           </tr>
                         </thead>
                         <tbody>
                           {currentData.map((el) => (
                             <tr key={el.id}>
                               <td>
                                 <div className="d-flex">
                                   <ImageComponent picture={el.img}></ImageComponent>
                                   <div className="mr-8 mt-0 mt-sm-1 d-block">
                                     <h6 className="mb-1 fs-14">
                                     <Link to={"/admin/clientactivity/" + el.nom}>{el.nom} {el.prenom}</Link>
                                     </h6>
                                     <p className="text-muted mb-0 fs-12">{el.email}</p>
                                   </div>
                                 </div>
                               </td>

                               <td>{el.motif}</td>
                               <td>{el.date_debut}</td>
                               <td>{el.date_fin}</td>

                               <td>
                                 {el.type === t("demande") && <span className="badge badge-warning">{t("demande")}</span>}
                                 {el.type === t("teletravail") && el.valide == 0 && (
                                   <span className="badge badge-danger">{t("refusé")}</span>
                                 )}
                                 {el.type === t("teletravail") && el.valide == 1 && (
                                   <span className="badge badge-success">{t("accepté")}</span>
                                 )}
                               </td>

                               <td className="text-left">
                                 {el.type === t("demande") && (
                                   <>
                                     <Link
                                       to="#"
                                       className="action-btns"
                                       data-tip="accepter"
                                       onClick={() => handleAccepte(el.id)}
                                     >
                                       {/* <i className="feather feather-edit text-info" /> */}
                                       <i className="fa fa-check-circle text-success"></i>
                                     </Link>
                                     <Link
                                       to="#"
                                       className="action-btns"
                                       data-tip="refuser"
                                       onClick={() => handleRefuse(el.id)}
                                     >
                                       {/* <i className="feather feather-edit text-info" /> */}
                                       <i className="fa fa-ban text-danger"></i>
                                     </Link>
                                   </>
                                 )}

                                 <Link
                                   to="#"
                                   className="action-btns"
                                   data-tip="supprimer"
                                   onClick={() => handleDelete(el.id)}
                                 >
                                   {/* <i className="feather feather-edit text-info" /> */}
                                   <i className="feather feather-x text-danger" />
                                 </Link>
                               </td>
                             </tr>
                           ))}
                         </tbody>
                       </table>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-sm-12 col-md-5">
                       <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                         {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                       </div>
                     </div>
                     <div className="col-sm-12 col-md-7">
                       <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                     </div>
                   </div>
                 </div>
               </div>
             )}
           </div>}
           
         </div>
       </div>
      

       <div className="col-xl-3 col-md-12 col-lg-12">
         <div className="card">
           <div className="card-header  border-0">
             <h4 className="card-title">{t("Aperçu Télétravail")}</h4>
           </div>
           <div className="card-body">
             <div id="leavesoverview" className="mx-auto pt-2" />
             <div className="row pt-7 pb-5  mx-auto text-center">
               <div className="col-md-12 mx-auto d-block">
                 <div className="row">
                   <div className="col-md-12 mt-3">
                     <div className="d-flex font-weight-semibold">
                       <PieChartTeletravail />
                     </div>
                   </div>

                   {/* <div className="col-md-12 mt-3">
                     <div className="d-flex font-weight-semibold">
                       <span className="dot-label badge-success mr-2 my-auto" />
                       Vacciné
                     </div>
                   </div>

                   <div className="col-md-12 mt-3">
                     <div className="d-flex font-weight-semibold">
                       <span className="dot-label bg-danger mr-2 my-auto" />
                       Non vacciné
                     </div>
                   </div> */}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
      
     
    </>
  );
}

export default ListTeletravail;
/*<div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>       
                  </div>*/