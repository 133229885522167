/**
 * This function filters an array of objects by a given value.
 * @param arrayObject - The array of objects that you want to filter.
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
// export function filterTodayDate(arrayObject, value) {
//   if (arrayObject.success != "0") {
//     var todayDate = new Date().toISOString().slice(0, 10);
//     return arrayObject.filter((el) => {
//       return el[value].split(" ")[0] == todayDate;
//     });
//   }


// }


import moment from "moment";

export function filterTodayDate(arrayObject, value) {
  if (arrayObject.success !== "0") {
    const todayDate = moment().format("YYYY-MM-DD");
    
    return arrayObject.filter((el) => {
      const itemDate = moment(el[value]).format("YYYY-MM-DD");
      return itemDate === todayDate;
    });
  }
  return [];
}

export const removeAttributeFromObjects = (dataList, attributeToRemove) => {
  return dataList.map((item) => {
      const { [attributeToRemove]: _, ...rest } = item; // Destructure to exclude the attribute
      return rest;
  });
};